import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '../store'
import Dp from '../views/dp/login.vue';
// import Dp from "../views/dp/index.vue"
import Login from '../views/pc/login.vue';
import Home from '../views/pc/home.vue';
import Magnify from '../views/pc/magnify.vue';
import Layout from '../views/pc';
import dp from '../assets/pc/大屏-灰色.png';
import supervision from '../assets/pc/supervision.png';
import dataAnalysis from '../assets/pc/dataAnalysis.png';
import qk from '../assets/pc/快考-灰色.png';
import sjk from '../assets/pc/数据库-灰色.png';
import hs from '../assets/pc/话术-灰色.png';
import xt from '../assets/pc/系统-灰色.png';
import sjcs from '../assets/pc/数据传输-灰色.png';
import icuMagnify from '@/views/pc/components/dataAnalysis/icuMagnify';
import nextIndex from '@/views/dp/nextIndex';

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/magnify',
    name: 'magnify',
    component: Magnify
  },
  {
    path: '/icumagnify',
    name: 'icu_magnify',
    component: icuMagnify
  },
  {
    path: '/nextIndex',
    name: 'icu_magnify',
    component: nextIndex
  },
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/bigScreen',
    component: Dp,
    redirect: '/dp',
    meta: { title: '数字营销大屏', icon: dp },
    children: [
      {
        path: '/dp',
        name: 'dp',
        meta: { title: '数字营销大屏' }
      }
    ]
  },

  {
    path: '/inspection',
    component: Layout,
    redirect: '/inspection/overview',
    meta: { title: '云巡检', icon: supervision },
    children: [
      {
        path: '/inspection/overview',
        name: 'overview',
        component: () => import('@/views/pc/components/inspection/overview'),
        meta: { title: '预警事项' }
      },
      {
        path: '/inspection/promoterWarn',
        name: 'promoterWarn',
        component: () => import('@/views/pc/components/inspection/promoterWarn'),
        meta: { title: '促销员巡检列表', keepAlive: true }
      },
      {
        path: '/inspection/promoterWarn/warnDetail',
        name: 'PwarnDetail',
        component: () => import('@/views/pc/components/inspection/PwarnDetail'),
        meta: { title: '' }
      },
      {
        path: '/inspection/supervisorWarn',
        name: 'supervisorWarn',
        component: () => import('@/views/pc/components/inspection/supervisorWarn'),
        meta: { title: '督导员预警列表', keepAlive: true }
      },
      {
        path: '/inspection/supervisorWarn/warnDetail',
        name: 'SwarnDetail',
        component: () => import('@/views/pc/components/inspection/SwarnDetail'),
        meta: { title: '' }
      },
      {
        path: '/inspection/alertLog',
        name: 'alertLog',
        component: () => import('@/views/pc/components/inspection/alertLog'),
        meta: { title: '预警日志' }
      }
    ]
  },

  {
    path: '/dataAnalysis',
    component: Layout,
    redirect: '/dataAnalysis/project',
    meta: { title: '数据分析', icon: dataAnalysis },
    children: [
      {
        path: '/dataAnalysis/project',
        name: 'project',
        component: () => import('@/views/pc/components/dataAnalysis/project'),
        meta: { title: '项目分析' }
      },
      {
        path: '/dataAnalysis/stores',
        name: 'stores',
        component: () => import('@/views/pc/components/dataAnalysis/stores'),
        meta: { title: '门店分析' }
      },
      {
        path: '/dataAnalysis/staff',
        name: 'staff',
        component: () => import('@/views/pc/components/dataAnalysis/staff'),
        meta: { title: '人员分析', keepAlive: true }
      },
      {
        path: '/dataAnalysis/staff/staffToday',
        name: 'staffToday',
        component: () => import('@/views/pc/components/dataAnalysis/staffToday'),
        meta: { title: '' }
      },
      {
        path: '/dataAnalysis/stores/storeDetails',
        name: 'storeDetails',
        component: () => import('@/views/pc/components/dataAnalysis/storeDetails'),
        meta: { title: '' }
      },
      {
        path: '/dataAnalysis/project/projectDetails',
        name: 'projectDetails',
        component: () => import('@/views/pc/components/dataAnalysis/projectDetails'),
        meta: { title: '' }
      },
      {
        path: '/dataAnalysis/dataExport',
        name: 'dataExport',
        component: () => import('@/views/pc/components/dataAnalysis/dataExport'),
        meta: { title: '数据导入/导出' }
      },
      {
        path: '/dataAnalysis/locus',
        name: 'locus',
        component: () => import('@/views/pc/components/dataAnalysis/locus'),
        meta: { title: '轨迹分析' }
      }
    ]
  },

  {
    path: '/quickTest',
    component: Layout,
    redirect: '/quickTest/testCase',
    meta: { title: '快考分析', icon: qk },
    children: [
      {
        path: '/quickTest/testCase',
        name: 'testCase',
        component: () => import('@/views/pc/components/quickTest/testCase'),
        meta: { title: '考试情况', keepAlive: true }
      },
      {
        path: '/quickTest/testCase/detail',
        name: 'testCaseDetail',
        component: () => import('@/views/pc/components/quickTest/testCaseDetail'),
        meta: { title: '' }
      }
    ]
  },

  {
    path: '/wordsAnalyze',
    component: Layout,
    redirect: '/wordsAnalyze/wordslibrary',
    meta: { title: '话术分析', icon: hs },
    children: [
      {
        path: '/wordsAnalyze/wordslibrary',
        name: 'wordslibrary',
        component: () => import('@/views/pc/components/wordsAnalyze/wordslibrary'),
        meta: { title: '标准话术库' }
      },
      {
        path: '/wordsAnalyze/audioRecord',
        name: 'audioRecord',
        component: () => import('@/views/pc/components/wordsAnalyze/audioRecord'),
        meta: { title: '音频记录', keepAlive: true }
      },
      {
        path: '/wordsAnalyze/audioRecord/audioDetail',
        name: 'audioDetail',
        component: () => import('@/views/pc/components/wordsAnalyze/audioDetail'),
        meta: { title: '' }
      },
      {
        path: '/wordsAnalyze/analyze',
        name: 'analyze',
        component: () => import('@/views/pc/components/wordsAnalyze/analyze'),
        meta: { title: '话术分析' }
      }
    ]
  },

  {
    path: '/infoManage',
    component: Layout,
    redirect: '/infoManage/supervisRule',
    meta: { title: '基础信息管理', icon: sjk },
    children: [
      {
        path: '/infoManage/supervisRule',
        name: 'supervisRule',
        component: () => import('@/views/pc/components/infoManage/supervisRule'),
        meta: { title: '督导规则列表' }
      },
      {
        path: '/infoManage/supervisRule/set',
        name: 'supervisRuleSet',
        component: () => import('@/views/pc/components/infoManage/ruleSet'),
        meta: { title: '' }
      },
      {
        //  热词管理
        path: '/infoManage/hotwords',
        name: 'hotwords',
        component: () => import('@/views/pc/components/infoManage/hotWordsManage'),
        meta: { title: '热词管理', keepAlive: true }
      },
      {
        path: '/infoManage/hotwords/hotWordSet',
        name: 'hotWordSet',
        component: () => import('@/views/pc/components/infoManage/hotWordSet'),
        meta: { title: '' }
      }
    ]
  },

  {
    path: '/systemManage',
    component: Layout,
    redirect: '/systemManage/customer',
    meta: { title: '系统管理', icon: xt },
    children: [
      {
        path: '/systemManage/customer',
        name: 'customer',
        component: () => import('@/views/pc/components/sysManage/customer'),
        meta: { title: '客户管理', keepAlive: true }
      },
      {
        path: '/systemManage/customer/cusInfo',
        name: 'cusInfo',
        component: () => import('@/views/pc/components/sysManage/cusInfo'),
        meta: { title: '' }
      },
      {
        path: '/systemManage/role',
        name: 'role',
        component: () => import('@/views/pc/components/sysManage/role'),
        meta: { title: '角色管理', keepAlive: true }
      },
      {
        path: '/systemManage/role/roleInfo',
        name: 'roleInfo',
        component: () => import('@/views/pc/components/sysManage/roleInfo'),
        meta: { title: '' }
      },
      {
        path: '/systemManage/account',
        name: 'account',
        component: () => import('@/views/pc/components/sysManage/account'),
        meta: { title: '账户管理', keepAlive: true }
      },
      {
        path: '/systemManage/account/accountInfo',
        name: 'accountInfo',
        component: () => import('@/views/pc/components/sysManage/accountInfo'),
        meta: { title: '' }
      },
      {
        path: '/systemManage/industry',
        name: 'industry',
        component: () => import('@/views/pc/components/sysManage/industry'),
        meta: { title: '行业管理', keepAlive: true }
      },
      {
        path: '/systemManage/industry/indusInfo',
        name: 'indusInfo',
        component: () => import('@/views/pc/components/sysManage/indusInfo'),
        meta: { title: '' }
      },
      {
        path: '/systemManage/log',
        name: 'log',
        component: () => import('@/views/pc/components/sysManage/log'),
        meta: { title: '日志' }
      }
    ]
  },

  {
    path: '/dataTransfer',
    component: Layout,
    redirect: '/dataTransfer/dataTest',
    meta: { title: '数据传输检测', icon: sjcs },
    children: [
      {
        path: '/dataTransfer/dataTest',
        name: 'dataTest',
        component: () => import('@/views/pc/components/dataTransfer/dataTest'),
        meta: { title: '数据传输情况' }
      }
    ]
  }
];

export const menuRoutes = routes.filter(item => {
  return item.children;
});

const router = new VueRouter({
  routes
  // mode:"history",
});

export default router;
