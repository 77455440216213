<template>
  <div>
    <Cu-Header-Index />
    <div class="project_overview_periphery_left_mapcontent">
      <div class="blobProp">
        <div
          :class="
            index == selectIndex ? 'info-wrap bg-girl' : 'info-wrap bg-boy'
          "
          v-for="(item, index) in userList"
          :key="index"
          @click="getIcoList(item, index)"
        >
          <div class="left">
            <span :class="item.sex === 0 ? 'img boy' : 'img girl'"></span>
          </div>
          <div class="right">
            <p class="posi">
              <span class="name">{{ item.nickName }}</span>
              <span>{{ item.spaceName }}</span>
            </p>
            <!-- <p :class="item.online === 'Y' ? 'status inline' : 'status outline'">
                  <span class="point"></span>
                  {{ item.online === "Y" ? "在线" : "离线" }}
                </p> -->
            <p class="time">设备编号：{{ item.deviceNo }}</p>
          </div>
        </div>
        <p v-show="userList.length === 0" class="no-data">暂无数据</p>
      </div>
      <div id="yzMap" style="width: 100%; height: 93vh; margin-top: 7vh"></div>
    </div>
  </div>
</template>
  
  <script>
function bgps_gps(bd_lng, bd_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = bd_lng - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return { lng: gg_lng, lat: gg_lat };
}
import MapLoader from "@/plugin/aMap";
import CuHeaderIndex from "@/views/components/HeaderIndex";
import {
  getIocusUserList,
  getIcoList,
  getMarkerList,
} from "@/api/dataAnalysis/staff";

export default {
  components: {
    CuHeaderIndex,
  },
  data() {
    return {
      thisUserNickName: "",
      selectIndex: null,
      icolist: [],
      markerList: [],
      center: [121.499718, 31.239703],
      userList: [],
      map: null,
      endDate: "",
      startDate: "",
      getUerId: "",
      formData: {
        spaceId: "",
        projectId: "",
        nickName: "",
        deviceNo: "",
      },
    };
  },
  methods: {
    addMark() {
      let that = this;
      let arr;
      if (that.icolist.length === 0) {
        arr = [];
      } else {
        arr = that.icolist.map((i) => {
          let p = [];
          p.push(parseFloat(i.longitude));
          p.push(parseFloat(i.latitude));
          return p;
        });
      }
      if (window.pathSimplifierIns) {
        pathSimplifierIns.setData([]);
      }
      // 用户轨迹
      AMapUI.load(
        ["ui/misc/PathSimplifier", "lib/$"],
        function (PathSimplifier, $) {
          if (!PathSimplifier.supportCanvas) {
            alert("当前环境不支持 Canvas！");
            return;
          }
          let defaultRenderOptions = {
            renderAllPointsIfNumberBelow: 1000,
            pathLineStyle: {
              lineWidth: 7,
              borderWidth: 1,
              strokeStyle: "#479eeb",
              lineWidth: 7,
            },
            pathLineHoverStyle: {
              lineWidth: 7,
            },
            keyPointStyle: {
              radius: 5,
            },
          };
          let pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            //autoSetFitView:false,
            map: that.map, //所属的地图实例

            getPath: function (pathData, pathIndex) {
              return pathData.path;
            },
            getHoverTitle: function (pathData, pathIndex, pointIndex) {
              let item = that.icolist[pointIndex];
              let content;

              if (pointIndex >= 0) {
                content = `
             <div class="mark-wrap">
              <p class="info">地点：${item.placeName}</p>
            <p class="info">经纬度：${item.longitude},${item.latitude}</p>
            <p class="info">定位时间：${item.createDate}</p>
  
            <p class="info">详细地址：${item.address}</p>
          </div>
          `;

                return content;
              }
              content = `
             <div class="mark-wrap">
              <p class="info">用户：${pathData.name}</p>
            <p class="info">，轨迹点数量：${pathData.path.length}</p>
          </div>
          `;
              return pathData.name + "，轨迹点数量" + pathData.path.length;
            },
            renderOptions: defaultRenderOptions,
          });

          window.pathSimplifierIns = pathSimplifierIns;

          //设置数据
          pathSimplifierIns.setData([
            {
              name: that.thisUserNickName + "的行动轨迹",
              path: arr,
            },
          ]);

          //对第一条线路（即索引 0）创建一个巡航器
          let navg1 = pathSimplifierIns.createPathNavigator(0, {
            loop: true, //循环播放
            speed: 1000, //巡航速度，单位千米/小时
          });

          navg1.start();
        }
      );
    },
    async getIcoList(item, index) {
      this.icolist = [];
      this.markerList = [];
      this.changeUserId = item.deviceNo;
      // let data = this.date;
      if (
        typeof this.startDate == "undefined" ||
        this.startDate == null ||
        this.startDate == ""
      ) {
        this.$message("请选择时间");
      } else {
        let icoData = {
          startDate: this.startDate,
          endDate: this.endDate,
          deviceNo: item.deviceNo,
        };
        this.selectIndex = index;
        this.thisUserNickName = item.nickName;
        await getMarkerList(icoData).then((res) => {
          if (res.data.length == 0) {
            this.$message("暂无门店信息");
            this.markerList = [];
            this.map.clearMap();
            return false;
          } else {
            this.markerList = res.data;
          }
        });
        await getIcoList(icoData).then((res) => {
          if (res.data.length == 0) {
            if (window.pathSimplifierIns) {
              //通过该方法清空上次传入的轨迹
              pathSimplifierIns.setData([]);
            }
            this.$message("暂无轨迹信息");
            this.icolist = [];
            return false;
          }
          this.icolist = res.data;
          // this.initMap();
          this.addMark();
          for (var i = 0; i < this.markerList.length; i++) {
            this.addIcon(
              this.markerList[i].longitude,
              this.markerList[i].latitude,
              this.markerList[i].spaceName,
              this.markerList[i].spaceNo,
              this.markerList[i].spacePath,
              this.markerList[i].spaceId,
              this.markerList[i].isPrimary
            );
          }
        });
      }
    },
    initMap() {
      let self = this;
      let aMapScript = document.createElement("script");
      aMapScript.setAttribute(
        "src",
        "https://webapi.amap.com/maps?v=1.4.13&key=ec85d3648154874552835438ac6a02b2&plugin=AMap.PlaceSearch,AMap.AdvancedInfoWindow"
      );
      document.head.appendChild(aMapScript);
      let aMapScript1 = document.createElement("script");
      aMapScript1.setAttribute(
        "src",
        "https://webapi.amap.com/ui/1.1/main.js?v=1.1.1"
      );
      document.head.appendChild(aMapScript1);
      aMapScript.onload = function () {
        let center = bgps_gps(self.center[0], self.center[1]);
        let map = new AMap.Map("yzMap", {
          center: [center.lng, center.lat], //地区位置
          resizeEnable: true,
          zoom: 15,
          isHotspot: false,
        });
        //        AMap.plugin([
        //     'AMap.ToolBar',
        // ], function(){
        //     // 在图面添加工具条控件, 工具条控件只有缩放功能
        //     map.addControl(new AMap.ToolBar());
        // });
        self.map = map;
        var infoWindow;
        AMap.service("AMap.AdvancedInfoWindow", function () {
          infoWindow = new AMap.AdvancedInfoWindow({});
        });
        // var placeSearch = new AMap.PlaceSearch(); //构造地点查询类
        AMap.service("AMap.PlaceSearch", function () {
          var placeSearch = new AMap.PlaceSearch({
            // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
            city: "010",
          });
        });
        //回调函数
        function placeSearch_CallBack() {
          //infoWindow.open(map, result.lnglat);
          //var poiArr = data.poiList.pois;
          var location = [121.740318, 31.054126];
          infoWindow.setContent(createContent());
          infoWindow.open(self.map, location);
        }
      };
    },
    getUserList() {
      getIocusUserList(this.formData).then((res) => {
        this.userList = res.data;
      });
    },
    addIcon(left, right, spaceName, spaceNo, spacePath, spaceId, isPrimary) {
      var _this = this;
      var viaMarker = new AMap.Marker({
        position: new AMap.LngLat(left, right),
      });
      var circle = new AMap.Circle({
        center: [left, right],
        radius: 100, //半径
        strokeColor: isPrimary == "Y" ? "#e4cf14" : "#479eeb",
        strokeOpacity: 1,
        fillOpacity: 0.2,
        strokeStyle: "solid",
        // 线样式还支持 'dashed'
        fillColor: "transparent",
        zIndex: 50,
      });

      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -70) });
      viaMarker.content =
        "<div class='textBox'><ul><li><p>门店编号：</p><span>" +
        spaceNo +
        "</span></li><li><p>门店名称：</p><span>" +
        spaceName +
        "</span></li><li><p>门店地址：</p><span>" +
        spacePath +
        "</span></li></ul></div>";
      viaMarker.on("click", markerClick);
      infoWindow.close();
      function markerClick(e) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(_this.map, e.target.getPosition());
      }
      // _this.map.clearMap();
      circle.setMap(_this.map);
      _this.map.add([viaMarker]);
      // 缩放地图到合适的视野级别
      _this.map.setFitView([circle]);
    },
  },
  mounted() {
    this.endDate = this.$route.query.endDate;
    this.startDate = this.$route.query.startDate;
    this.formData.spaceId = this.$route.query.spaceId;
    this.formData.projectId = this.$route.query.projectId;
    this.formData.nickName = this.$route.query.nickName;
    this.formData.deviceNo = this.$route.query.deviceNo;
    this.getUserList();
    MapLoader().then(
      (AMap) => {
        this.map = new AMap.Map("container", {
          zoom: 15,
          resizeEnable: true,
        });
        this.initMap();
        // ....你的操作
      },
      (e) => {}
    );
  },
};
</script>
  
  <style lang="scss" scoped>
.project_overview_periphery_right_main {
  background: rgba(78, 87, 170, 0.1);
  border-radius: 6px;
}

.project_overview_periphery_right_main_item {
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_periphery_right_main_centent {
  background: rgba(255, 255, 255, 0.2);
}

.project_overview_periphery_right_main_centent_item {
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_periphery_left_mapcontent {
  position: relative;
  height: 100vh;
}

#yzMap {
  position: absolute;
}

.search {
  position: absolute;
  top: calc(23px + 7vh);
  left: calc(61px + 7vh);
  z-index: 999;
}

.search /deep/ .el-input {
  box-shadow: 0px 4px 4px 0px rgba(165, 165, 165, 0.3);
}

.search /deep/ .el-input__inner {
  border-radius: 8px !important;
  border: none !important;
}

.blobProp {
  background: #ffffff;
  box-shadow: 0px 6px 6px 0px rgba(117, 117, 117, 0.5);
  border-radius: 15px;
  padding: 31px 26px 45px 21px;
  position: absolute;
  bottom: 6px;
  right: 21px;
  z-index: 999;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 635px;
}

.titleBox p {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.textBox {
  display: flex;
  align-items: center;
}

.textBox span {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.blobProp {
  padding: 31px 26px 45px 21px;
  position: absolute;
  bottom: 6px;
  right: 21px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
  mix-blend-mode: normal;
  box-shadow: 0px 20px 26px rgba(0, 0, 0, 0.05), 0px 8px 9px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(30px);
  border-radius: 16px;
  font-family: "PingFang SC";
  font-style: normal;
  height: 50vh;
  min-width: 314px;
  overflow: hidden;
  overflow-y: scroll;

  .info-wrap {
    display: flex;
    background: rgba(24, 144, 255, 0.1);
    border-radius: 20px;
    padding: 15px;
    cursor: pointer;
    margin: 10px;

    .left {
      .img {
        display: inline-block;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        border-radius: 11px;
        margin-top: 15px;
      }

      .boy {
        background: rgba(24, 144, 255, 0.2);
        box-shadow: 0px 4px 12px rgba(145, 154, 163, 0.25);
        background: url("../../../../assets/img/ic_user0.png") no-repeat;
        background-size: 100% 100%;
      }

      .girl {
        background: rgba(255, 189, 123, 0.3);
        box-shadow: 0px 4px 12px rgba(145, 154, 163, 0.25);
        background: url("../../../../assets/img/ic_user1.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .right {
      margin-left: 10px;
      vertical-align: middle;

      .posi {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;

        .name {
          margin-right: 20px;
        }
      }
    }
  }

  .bg-boy {
    background: rgba(24, 144, 255, 0.1);
  }

  .bg-girl {
    background: rgba(255, 170, 100, 0.1);
  }

  .status {
    padding: 0;
    margin: 0;
    margin: 10px 0;
    font-size: 12px;

    font-weight: 400;
    font-size: 14px;
  }

  .inline {
    color: #23b899;
  }

  .outline {
    color: #f84a22;
  }

  .time {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: gray;
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .no-data {
    text-align: center;
    margin-top: 50%;
    color: #b7b7b7;
  }
}
/deep/.amap-info-content {
  padding: 0;
}
/deep/.textBox {
  min-width: 400px;
  border: 2px solid #ffffff;
  box-shadow: 0px 15px 20px rgba(118, 139, 175, 0.4),
    inset 0px 0px 30px 10px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(9px);
  border-radius: 16px;
  padding: 20px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    p {
      margin: 0;
    }
    span {
      display: inline-block;
    }
  }
}
</style>