<template>
  <div class="home">
    <div class="linel"><span></span></div>
    <div class="liner"><span></span></div>
    <div class="logo"></div>
    <div class="logout" @click="onLogout">
      <i></i>
      <span>退出登录</span>
    </div>
    <div class="nav">
      <div class="center"></div>
      <div class="frame tl" @click="turnTo('manage')">
        <img src="../../assets/img/home-config.png" alt="" />
        <div>
          <div class="cn">项目配置</div>
          <div class="en">Project configuration</div>
        </div>
      </div>
      <div class="frame tr" @click="turnTo('cultivate')">
        <!-- <div class="frame tr"> -->
        <img src="../../assets/img/home-train.png" alt="" />
        <div>
          <div class="cn">培训管理</div>
          <div class="en">Training management</div>
        </div>
      </div>
      <div class="frame bl" @click="turnTo('analysis')">
        <img src="../../assets/img/home-manage.png" alt="" />
        <div>
          <div class="cn">项目管理</div>
          <div class="en">Project management</div>
        </div>
      </div>
      <!-- <div class="frame br">
        <img src="../../assets/img/home-analyse.png" alt="" />
        <div>
          <div class="cn">分析报告</div>
          <div class="en">analysis report</div>
        </div>
      </div> -->
    </div>

    <!-- <div class="title">
      <img src="../../assets/pc/loginLogo.png" alt="" style="height: 9.4vh" />
    </div> -->
    <!-- <div class="wrap">
      <div class="cell cultivate" @click="turnTo('cultivate')">培训系统</div>
      <div class="cell analysis" @click="turnTo('analysis')">分析系统</div>
      <div class="cell manage" @click="turnTo('manage')">管理系统</div>
    </div> -->
  </div>
</template>
<script>
import {
  getUserName,
  getPassword,
  getToken,
  getNickName,
  removeToken,
  removeNickName,
  removeUserName,
  removePassword
} from '@/utils/auth.js';
import { logout } from '@/api/login.js';
import { mapState, mapActions } from 'vuex';
export default {
  computed: {},
  methods: {
    turnTo(val) {
      let username = getUserName();
      let password = getPassword();
      let token = getToken();
      let nickName = getNickName();
      switch (val) {
        case 'analysis':
          this.$router.push({ path: '/inspection/overview' });
          break;
        case 'cultivate':
          window.location.href = `https://exam.j-iletech.com.cn/prelogin?token=${token}`;
          break;
        case 'manage':
          // window.location.href = `http://device.j-iletech.com.cn/build/#/?username=${username}&password=${password}`;
          window.location.href = `http://www.j-iletech.com.cn/jxzy-data/#/?token=${token}&nickName=${nickName}`;
          // window.location.href = `http://localhost:8080/#/?token=${token}&nickName=${nickName}`;
          break;
      }
    },
    onLogout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logout().then(() => {
          removeToken();
          removeUserName();
          removePassword();
          removeNickName();
          // location.reload();
          this.$router.push('/login');
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url('https://pic.imgdb.cn/item/606571b18322e6675c9b7047.gif') no-repeat;
  background-size: 100% 100%;
  &::before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0.926vh;
    margin: auto;
    width: 98%;
    height: 0.185vh;
    background: rgba(104, 91, 226, 0.9);
  }
  &::after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0.926vh;
    margin: auto;
    width: 98%;
    height: 0.185vh;
    background: rgba(104, 91, 226, 0.9);
  }
  .linel {
    position: fixed;
    left: 0.52vw;
    top: 0;
    width: 0.1vw;
    height: 100%;
    background: rgba(104, 91, 226, 0.9);
    span {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.15vw;
      height: 9.26vh;
      background: #685be2;
      border-radius: 0px 8px 8px 0px;
    }
  }
  .liner {
    position: fixed;
    right: 0.52vw;
    top: 0;
    width: 0.1vw;
    height: 100%;
    background: rgba(104, 91, 226, 0.9);
    span {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.15vw;
      height: 9.26vh;
      background: #685be2;
      border-radius: 8px 0px 0px 8px;
    }
  }
  .logo {
    float: left;
    width: 10.52vw;
    height: 6.48vh;
    // background: url("../../assets/img/logo.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 10.73vw;
    margin-top: 3.05vh;
  }
  .logout {
    float: right;

    width: 7.4vw;
    height: 4.2vh;
    background: url('../../assets/img/logout.png') no-repeat;
    background-size: 100% 100%;
    margin-right: 7.9vw;
    margin-top: 4.17vh;
    text-align: center;
    line-height: 3.5vh;
    cursor: pointer;
    i {
      vertical-align: middle;
      display: inline-block;
      width: 0.9375vw;
      height: 1.85vh;
      // margin-top: 0.75vh;
      background: url('../../assets/img/user.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 2px;
    }
    span {
      font-size: 0.729vw;
      // font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
  }
  .nav {
    position: relative;
    width: 81%;
    height: 66.86vh;
    margin: 0 auto;
    margin-top: 18.7vh;
    .center {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      // width: 46.5%;
      width: 66.86vh;
      height: 100%;
      background: url('../../assets/img/home-center.png') no-repeat;
      background-size: 100% 100%;
    }
    .frame {
      position: absolute;
      display: flex;
      align-items: center;
      width: 18.90625vw;
      height: 19.352vh;
      background: url('../../assets/img/home-frame.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      img {
        width: 5.052vw;
        height: 9.63vh;
        margin-left: 1.5625vw;
        margin-right: 1.25vw;
        margin-top: -1.8vh;
      }
      .cn {
        margin-bottom: 1.48vh;
        // font-size: 24px;
        font-size: 1.25vw;
        // font-weight: 500;
        color: #ffffff;
      }
      .en {
        padding-bottom: 0.648vh;
        border-bottom: 2px solid #9ba4f7;
        // font-size: 16px;
        font-size: 0.83vw;
        color: #9ba4f7;
      }
      &:hover {
        background: url('../../assets/img/home-frame-active.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .tl {
      left: 0;
      top: 3.24vh;
    }
    .tr {
      right: 0;
      top: 3.24vh;
    }
    .bl {
      left: 0;
      bottom: 3.98vh;
    }
    .br {
      right: 0;
      bottom: 3.98vh;
    }
  }
  .title {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    // background: url("../../assets/pc/loginLogo.png") no-repeat;
    // background-size: 22% 36%;
    // background-position: bottom center;
  }
  .wrap {
    margin-top: 12vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .cell {
      width: 17vw;
      height: 25vh;
      color: #fff;
      //   transform: scaleX(-1);
      font-size: 1.3rem;
      text-align: center;
      line-height: 32vh;

      cursor: pointer;
    }
    .cultivate {
      background: url('../../assets/pc/cultivate.png') no-repeat;
      background-size: 100% 100%;
    }
    .analysis {
      background: url('../../assets/pc/analysis.png') no-repeat;
      background-size: 100% 100%;
      margin: 0 5vh;
    }
    .manage {
      background: url('../../assets/pc/manage.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
