<template>
  <div class="app-wrapper">
    <div class="project_overview_box">
      <Cu-Header-Index />
      <div class="project_overview_content_box">
        <Cu-Nav-Menu-Left />
        <div class="appMain" :class="isCollapse ? 'collapseMain' : ''">
          <AppMain></AppMain>
          <div class="records" style="padding-bottom: 10px">
<!--            <span>Copyright©2021匠芯知音</span>-->
<!--            <span style="margin-top: 11px">沪公网备 沪ICP备2021014073-2</span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppMain from "./components/AppMain";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState, mapGetters } from "vuex";
import CuHeaderIndex from "@/views/components/HeaderIndex";
import CuNavMenuLeft from "@/views/components/NavMenuLeft";
export default {
  name: "Layout",
  components: {
    AppMain,
    CuNavMenuLeft,
    CuHeaderIndex,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
    ...mapGetters(["isCollapse"]),
    // isCollapse() {
    //   return !this.sidebar.opened;
    // },
  },
  mounted() {
    // console.log(this.$store.state)
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/mixin.scss";
@import "~@/assets/variables.scss";

.records span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #909090;
  line-height: 1;
  letter-spacing: 2.5px;
  display: block;
  text-align: center;
}

.appMain {
  position: absolute;
  top: 7vh;
  left: 16vw;
  width: calc(100% - 16vw);
  // height: 100%;
  min-height: calc(100% - 7vh);
  background: rgba(197, 201, 212, 0.15);
}
.collapseMain {
  left: 6vw;
  width: calc(100% - 6vw);
}
.openMain {
  left: 6vw;
  width: calc(100% - 6vw);
}
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
<style>
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
