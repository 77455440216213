
import {getInfoDs} from '@/api/menu'
import { getUrlKey } from "@/utils/validate"

const state = {
    menus: [],
    permissions:[]
};
const mutations = {
  SET_MENU: (state, menus) => {
    state.menus = menus;
    },
    SET_PERMISSION: (state, permissions) => {
    state.permissions = permissions;
  },
};
function getPermissions(menus, to) {
  menus.forEach(item => {
    if (item.children.length !== 0) {
      if (item.menuType === 'menu') {
        if (item.menuName === to.meta.title) {
          to.meta.show = true;
          to.meta.menuId=item.menuId
        } else {
          getPermissions(item.children,to);
        }
      } else if (item.menuType === 'button') {
        if (item.parentId === to.meta.menuId) {
          let buttons = [];
          buttons.push(item.menuName);
          to.buttons = buttons;
        } else {
          getPermissions(item.children,to);
        }
      }
    }
  })
 
}
const actions = {
    getInfo({ commit }) {
      let type = getUrlKey('type')
        if(type){
          
        }else{
          return new Promise(resolve => {
              getInfoDs().then(res => {
                  let menus = [];
                  let permissions = [];
                  res.menus.forEach(item => {
                      if (item.menuType === 'menu') {
                          menus.push(item.menuName) 
                      }
                      item.children.forEach(im => {
                          if (im.menuType === 'menu') {
                              menus.push(im.menuName) 
                        }
                        permissions.push(im);
                        
                      })
                  })
                permissions.forEach((item,index) => {
                  permissions[index].children = item.children.map(im => {
                    return im.menuName;
                  })
                })
                  commit('SET_MENU', menus);
                  commit('SET_PERMISSION', permissions);
                  resolve(menus);
              })
          })
        }
      }
    }

export default {
  state,
  mutations,
  actions,
};
