import axios from "axios";
import { Message, MessageBox } from "element-ui";
// import store from './store'
import { getToken, removeToken, removeNickName } from "@/utils/auth";
// console.log(process.env, '<<<<<')
const instance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/prod-api" : "",
  timeout: 1000 * 60,
});

const tip = (msg) => {
  Message({
    message: msg,
    type: "error",
  });
};

// request拦截器
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    tip(error);
    Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    const code = res.data.code || 200;
    const msg = res.data.msg;
    if (code === 500) {
      if (msg === "登录状态已过期") {
        MessageBox.confirm(
          "登录状态已过期，您可以继续留在该页面，或者重新登录",
          "系统提示",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            removeToken();
            removeNickName();
            location.reload();
            return res;
          })
          .catch(() => {
            return res;
          });
      } else {
        tip(msg);
        return Promise.reject(new Error(msg));
      }
    }
    if (code === 8888) {
      tip(msg);
      return Promise.reject(new Error(msg));
    }
    if (code === 200) {
      return Promise.resolve(res.data);
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default instance;
