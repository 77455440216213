<!-- 热词分析 -->
<template>
  <div class="dataBox">
    <div class="title">热词分析</div>
    <div class="paopao" ref="paopaoChart"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import lineCircle from "@/assets//img/words.png";
import { verbalTrickGet } from "@/api/dp/dphead";
export default {
  name: "Vue",
  components: {},
  props: {
    projectId: String,
    date: Array,
    placeCode: String,
  },
  data() {
    return {
      paopaoChart: {}, //用于初始化chart
      dataLists: [],
      code: "",
      lastProjectId: "",
    };
  },
  watch: {
    placeCode: {
      handler(val) {
        this.code = val;
        this.getData();
      },
    },
    projectId: {
      handler(val) {
        this.lastProjectId = val;
        this.getData();
      },
    },
  },
  computed: {
    lastDate() {
      return this.date;
    },
  },
  methods: {
    getData() {
      let option = {
        projectId: this.lastProjectId,
        startDate: this.lastDate[0],
        endDate: this.lastDate[1],
        provinceCode: this.code,
      };

      verbalTrickGet(option).then((res) => {
        if (res && res.code == 200) {
          let _this = this;
          _this.dataLists = res.data;
          _this.dataLists.forEach((e) => {
            var num = Math.floor(Math.random() * (100 - 50)) + 50;
            e.symbol = `image://${lineCircle}`;
            e.symbolSize = num;
            e.draggable = true;
            e.name = e.content;
            e.value = e.getCount;
          });
          _this.paopaoChart = echarts.init(_this.$refs.paopaoChart);
          _this.getpaopaoOptionData();
        }
      });
    },
    //获取泡泡类型数据
    getpaopaoOptionData() {
      let $this = this;
      setInterval(function () {
        // let random = (Math.random() * 100).toFixed(1) - 0;
        // $this.paopaoOption.series[0].data[0].value = random;
        // $this.paopaoOption.series[0].data[1].value = random;
        // $this.paopaoOption.series[0].data[2].value = random;
        // $this.paopaoOption.series[0].data[3].value = random;
        // $this.paopaoOption.series[0].data[4].value = random;
        // $this.paopaoOption.series[0].data[5].value = random;
        // $this.paopaoOption.series[0].data[6].value = random;
        // $this.paopaoOption.series[0].data[7].value = random;
        // $this.paopaoOption.series[0].data[8].value = random;
        let paopaoOption = {
          //提示框
          tooltip: {},
          animationDurationUpdate: function (idx) {
            // 越往后的数据延迟越大
            return idx * 10000;
          },
          animationEasingUpdate: "bounceIn",
          series: [
            {
              type: "graph",
              layout: "force",
              force: {
                repulsion: 100,
                edgeLength: 10,
              },
              label: {
                show: true,
                // formatter: ["{a|{c}}", "{b|{b}}"].join("\n"),
                formatter: function (data) {
                  return data.name;
                },
                // fontWeight: "1000",
                fontSize: "16",
                align: "center",
                // verticalAlign: "center",
                // style: {
                //   fill: "rgba(110, 193, 244, 0.2)",
                // },
                rich: {
                  a: {
                    color: "#4a9ffe",
                    fontSize: 18,
                    lineHeight: 30,
                    // textBorderColor: "transparent",
                    textBorderWidth: 0,
                  },
                  // b: {
                  //   color: "#4a9ffe",
                  //   fontSize: 14,
                  //   lineHeight: 30,
                  //   textBorderColor: "transparent",
                  //   textBorderWidth: 0,
                  // },
                },
              },
              data: $this.dataLists,
            },
          ],
        };
        $this.paopaoChart.setOption(paopaoOption);
      }, 1000);
    },
  },
  created() {},
  mounted() {
    // this.getData();
  },
};
</script>
<style lang="less" scoped>
.dataBox {
  width: 420px;
  height: 325px;
  background-image: url(../../../assets/img/rightList.png);
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .title {
    padding-left: 30px;
    font-family: "YouSheBiaoTiHei";
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.paopao {
  width: 420px;
  height: 280px;
}
</style>