import Cookies from "js-cookie"

const TokenKey = "Admin-Token"

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserName() {
  return Cookies.get("jxzy-username")
}

export function setUserName(username) {
  return Cookies.set("jxzy-username", username)
}

export function removeUserName() {
  return Cookies.remove("jxzy-username")
}

export function getPassword() {
  return Cookies.get("jxzy-password")
}

export function setPassword(password) {
  return Cookies.set("jxzy-password", password)
}

export function removePassword() {
  return Cookies.remove("jxzy-password")
}

export function getNickName() {
  return localStorage.getItem("nickName")
}

export function setNickName(name) {
  return localStorage.setItem("nickName", name)
}

export function removeNickName() {
  return localStorage.removeItem("nickName")
}
