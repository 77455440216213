<!-- 大屏二版 -->
<template>
  <div class="screenBox">
    <div class="top">
      <div>
        <img src="../../assets/img/inithead.png" alt="" />
      </div>
    </div>
    <div class="btm">
      <div>
        <img src="../../assets/img/dpbtm.png" alt="" />
      </div>
    </div>
    <!-- 返回 -->
    <div class="topAll">
      <div class="back" @click="goBack">返回</div>
      <div class="picker">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @blur="datePicker"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <!-- <div class="dateLists">
        <div
          v-for="(item, index) in dateLists"
          :key="index"
          class="listOne"
          :class="dateIndex == index ? 'activeTime' : ''"
          @click="dateChange(item, index)"
        >
          <div>{{ item.Text }}</div>
        </div>
      </div> -->
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="left">
        <left
          :projectId="projectId"
          :placeCode="placeCode"
          :date="date"
          @userChange="userChange"
          @placeChange="placeChange"
        ></left>
      </div>
      <div class="cent" style="position: relative">
        <div class="initBtn">
          <el-button type="text" @click="init()">重置</el-button>
        </div>
        <maps
          @mapChange="mapChange"
          :projectId="projectId"
          :date="date"
          :userId="userId"
          :spaceId="spaceId"
        ></maps>
      </div>
      <div class="right">
        <basicData
          :projectId="projectId"
          :date="date"
          :placeCode="placeCode"
        ></basicData>
        <wordsTechnique
          :projectId="projectId"
          :date="date"
          :placeCode="placeCode"
        ></wordsTechnique>
        <hotWords
          :projectId="projectId"
          :placeCode="placeCode"
          :date="date"
        ></hotWords>
      </div>
    </div>
  </div>
</template>

<script>
import left from "./component/left.vue";
import maps from "./component/map.vue";
import basicData from "./component/basicData.vue";
import hotWords from "./component/hotWords.vue";
import wordsTechnique from "./component/wordsTechnique.vue";
export default {
  name: "nextIndex",
  components: { left, maps, basicData, hotWords, wordsTechnique },
  props: {},
  data() {
    return {
      dateLists: [
        {
          Text: "当日",
          type: 0,
        },
        {
          Text: "周",
          type: 1,
        },
        {
          Text: "月",
          type: 2,
        },
        {
          Text: "年",
          type: 3,
        },
      ],
      dateIndex: 0,
      projectId: "",
      date: [],
      placeCode: "",
      userId: "",
      spaceId: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      // this.$router.go();
      location.reload(); // 类似于F5按钮刷新
    },
    // 获取人员id
    userChange(e) {
      this.userId = e.toString();
    },
    placeChange(e) {
      this.spaceId = e.toString();
    },
    // 获取省份信息
    mapChange(e) {
      this.placeCode = e.toString();
    },
    datePicker() {
      console.log(this.date);
    },
    goBack() {
      this.$router.push("/dp");
    },
    dateChange(item, index) {
      this.dateIndex = index;
    },
  },
  created() {},
  mounted() {
    this.projectId = this.$route.query.id;
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.screenBox {
  width: 100%;
  height: 1080px;
  // display: flex;
  // justify-content: space-between;
  background-image: url(../../assets/img/initbck.png);
  background-size: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
  .top {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    div {
      width: 1600px;
      width: 1600px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .btm {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    div {
      width: 1600px;
      height: 112px;
      margin: 0 auto;
      img {
        width: 100%;
        // height: 100%;
      }
    }
  }
}
.back {
  width: 72px;
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  box-sizing: border-box;
  background-image: url(../../assets/img/goback.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  color: #fff;
  cursor: pointer;
}
.picker {
  margin-left: 20px;
  margin-top: 5px;
  /deep/.el-input__inner {
    width: 280px;
    background-color: #7b56e4;
  }
  /deep/.el-range-input {
    background-color: #7b56e4;
    color: #fff;
  }
  /deep/.el-range-separator {
    color: #fff;
  }
}
.topAll {
  padding: 20px 10px;
  width: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: flex-start;
  color: #fff;
  box-sizing: border-box;
  .dateLists {
    display: flex;
    justify-content: flex-end;
    .listOne {
      width: 50px;
      height: 28px;
      color: #ffffff;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      background-image: url(../../assets/img/initDate.png);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-left: 10px;
      cursor: pointer;
    }
    .activeTime {
      background-image: url(../../assets/img/checkDate.png);
    }
  }
}
// 内容
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 420px;
    height: 940px;
    // background-color: aqua;
  }
  .right {
    width: 420px;
    height: 940px;
    // background-color: aqua;
  }
}
/deep/.el-button--text {
  color: #fff;
}
.initBtn {
  position: absolute;
  width: 100px;
  height: 30px;
  left: 0;
  top: 0px;
  z-index: 999;
}
</style>