<template>
  <div class="i_navmenuleft" :class="isCollapse ? 'collapse_menu' : ''">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      router
      :collapse="isCollapse"
      :default-openeds="openeds"
      @open="handleOpen"
      @close="handleClose"
      background-color="#FFF"
      text-color="#333333"
      active-text-color="#4E57AA"
    >
      <el-submenu
        v-show="item.meta.title && item.meta.show"
        v-for="(item, index) in menuRoutes"
        :index="item.path"
        :key="index"
      >
        <template slot="title">
          <img :src="item.meta.icon" alt="" />
          <span style="margin-left: 8px">{{ item.meta.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-show="im.meta.title && im.meta.show"
            v-for="(im, ix) in item.children"
            :key="ix"
            :index="im.path"
            >{{ im.meta.title }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { menuRoutes } from "@/router/index";
export default {
  name: "navmenuleft",
  components: {},
  computed: {
    ...mapGetters(["sidebar", "menus", "isCollapse"]),
    // isCollapse() {
    //   return !this.sidebar.opened;
    // },
  },
  data() {
    return {
      menuRoutes,
      openeds: [
        // "1","2","3","4","5","6","7","8"
      ],
    };
  },
  mounted() {
    let newMenus = [...this.menuRoutes];
    this.menuRoutes.forEach((item, index) => {
      if (this.menus.includes(item.meta.title)) {
        newMenus[index].meta.show = true;
        item.children.forEach((im, ix) => {
          if (this.menus.includes(im.meta.title)) {
            newMenus[index].children[ix].meta.show = true;
          } else {
            newMenus[index].children[ix].meta.show = false;
          }
        });
      } else {
        newMenus[index].meta.show = false;
      }
    });
    this.menuRoutes = newMenus;
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    itemClick(address) {
      this.$router.push(address);
    },
  },
};
</script>
<style scoped lang="less">
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.i_navmenuleft {
  position: fixed;
  top: 7vh;
  width: 16vw;
  padding-left: 0.7vw;
  padding-right: 0.7vw;
  height: 93vh;
  overflow-y: auto;
}
.collapse_menu {
  width: 6vw;
}
ul {
  margin: 0;
  padding: 0;
}
.el-menu {
  width: 100%;
  border-right: solid 0px #e6e6e6;
  transition: none !important;
}
.el-menu-item {
  padding-left: 53px !important;
  padding-right: 0 !important;
  min-width: auto !important;
}
</style>
<style>
.el-menu-item-group__title {
  padding: 0px 0 0px 0px !important;
}
.el-submenu__title:hover {
  background-color: #4e57aa !important;
  color: #fff !important;
  border-radius: 8px;
}
.el-submenu__title {
  border-radius: 8px;
}
</style>
