<template>
    <div>
      <Cu-Header-Index />
        <div class="project_overview_periphery_left_mapcontent">
            <div class="search">
              <el-form ref="form" :model="form" label-width="100px" style="display:flex;">
                <el-form-item label="查找门店：">
                    <el-select v-model="spaceName" filterable placeholder="请输入内容" @change="handleSelect">
                        <el-option
                            v-for="item in optionsData.spaces"
                            :key="item.spaceId"
                            :label="item.spaceName"
                            :value="item.spaceId">
                        </el-option>
                    </el-select>
                </el-form-item>
              </el-form>
          </div>
          <div class="blobProp">
              <div class="titleBox">
                  <p>{{ storeList[0].spaceName }}</p>
                  <div class="textBox">
                      <div>
                          <span style="color:rgba(78, 87, 170, 1);">人数：</span>
                          <span style="color:rgba(255, 197, 49, 1)">{{ storeList[0].userCount }}</span>
                      </div>
                      <div style="margin-left:30px;">
                          <span style="color:rgba(78, 87, 170, 1);">销售数量</span>
                          <span style="color:rgba(255, 197, 49, 1)">{{ storeList[0].saleCount }}</span>
                      </div>
                  </div>
              </div>
              <div >
                  <el-col :span="24">
                    <el-row class="project_overview_periphery_right_main">
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >主动沟通占比</el-col>
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >话术匹配度</el-col>
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >通话时长(min)</el-col>
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >离岗次数</el-col>
                    </el-row>
                    <el-row class="project_overview_periphery_right_main_centent">
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].activeTalkProp }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].verbalMatchingRate || 0}}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].staffDuration }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].odc }}</el-col
                        >
                    </el-row>
                  </el-col>
                  <el-col :span="24">
                    <el-row class="project_overview_periphery_right_main">
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >静止次数</el-col
                        >
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >静止时长(min)</el-col
                        >
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >运动步数</el-col
                        >
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >离岗时间(min)</el-col
                        >
                    </el-row>
                    <el-row class="project_overview_periphery_right_main_centent">
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].ssc }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].ssd }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].step }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[0].odd }}</el-col
                        >
                    </el-row>
                  </el-col>
              </div>
              <div v-if="storeList.length > 1" class="titleBox">
                  <p>{{ storeList[1].spaceName }}</p>
                  <div class="textBox">
                      <div>
                          <span style="color:rgba(78, 87, 170, 1);">人数：</span>
                          <span style="color:rgba(255, 197, 49, 1)">{{ storeList[1].userCount }}</span>
                      </div>
                      <div style="margin-left:30px;">
                          <span style="color:rgba(78, 87, 170, 1);">销售数量</span>
                          <span style="color:rgba(255, 197, 49, 1)">{{ storeList[1].saleCount }}</span>
                      </div>
                  </div>
              </div>
              <div v-if="storeList.length > 1">
                  <el-col :span="24">
                    <el-row class="project_overview_periphery_right_main">
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >主动沟通占比</el-col>
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >话术匹配度</el-col>
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >通话时长(min)</el-col>
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >离岗次数</el-col>
                    </el-row>
                    <el-row class="project_overview_periphery_right_main_centent">
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].activeTalkProp || 0 }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].verbalMatchingRate || 0 }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].staffDuration || 0 }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].odc || 0 }}</el-col
                        >
                    </el-row>
                  </el-col>
                  <el-col :span="24">
                    <el-row class="project_overview_periphery_right_main">
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >静止次数</el-col
                        >
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >静止时长(min)</el-col
                        >
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >运动步数</el-col
                        >
                        <el-col class="project_overview_periphery_right_main_item" :span="6"
                            >离岗时间(min)</el-col
                        >
                    </el-row>
                    <el-row class="project_overview_periphery_right_main_centent">
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].ssc || 0 }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].ssd || 0 }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].step || 0 }}</el-col
                        >
                        <el-col
                            :span="6"
                            class="project_overview_periphery_right_main_centent_item"
                            >{{ storeList[1].odd || 0 }}</el-col
                        >
                    </el-row>
                  </el-col>
              </div>
          </div>
          <div id="yzMap" style="width: 100%; height:93vh;margin-top:7vh;"></div>
        </div>
    </div>
</template>

<script>
function bgps_gps(bd_lng, bd_lat) {
  var X_PI = Math.PI * 3000.0 / 180.0;
  var x = bd_lng - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return {lng: gg_lng, lat: gg_lat}
}
import CuHeaderIndex from "@/views/components/HeaderIndex";
import {
  getInfo,
  getInfoDetails,
  getStoreTendency,
  getHistoryStatistics,
  getStoresvs
} from "@/api/dataAnalysis/project";
export default {
  components: {
    CuHeaderIndex,
  },
    data() {
        return {
            markers:[],
            form:{
                name:''
            },
            areas: [],
            areaProps: {
                lazy: true,
                checkStrictly: true,
                lazyLoad: this.loadDom,
            },
            spaceNameList: [],
            placeMarker: null,
            spaceName: "",
            showStore: false,
            mapObject: null,
            markerList: [],
            province: "",
            options: JSON.parse(localStorage.getItem('storeDetailsRow')),
            optionsData: {},
            storeList:[],
            spaceID:''
        }
    },
    created(){
        this.getDetails()
    },
    methods: {
        handleSelect(val){
            this.spaceID = val
            this.initMap()
        },
        getDetails(){
            getInfoDetails({
                projectId: this.options.storeProjectId, //this.options.projectId,
                spaceId: this.options.spaceId //this.options.spaceId
            }).then(res=>{
                if(res.code == 200){
                    this.optionsData = res.data
                }
                getStoresvs({
                    projectId: this.options.storeProjectId, //this.options.projectId,
                    spaceIds: this.options.spaceId //this.options.spaceId
                }).then(val=>{
                    this.storeList = val.data
                })
            })
        },
        // 加载高德地图地图
        initMap() {
            let self = this;
            let aMapScript = document.createElement("script");
            aMapScript.setAttribute(
                "src",
                "https://webapi.amap.com/maps?v=1.4.13&key=ec85d3648154874552835438ac6a02b2&plugin=AMap.PlaceSearch,AMap.AdvancedInfoWindow"
            );
            document.head.appendChild(aMapScript);
            aMapScript.onload = function () {
                let center = bgps_gps(self.optionsData.longitude,self.optionsData.latitude)
                let map = new AMap.Map("yzMap", {
                    center: [center.lng, center.lat], //地区位置
                    resizeEnable: true,
                    zoom: 15,
                    isHotspot: false,
                });
                if(self.spaceID != '' && self.spaceID != null){
                    let res = {}
                    self.optionsData.spaces.map(item=>{
                        if(item.spaceId == self.spaceID){
                            res = bgps_gps(item.longitude, item.latitude)
                        }
                    })
                    console.log(res,'<<<<')
                    map.setZoomAndCenter(15, [res.lng,res.lat]);
                }
                self.mapObject = map;
                var placeSearch = new AMap.PlaceSearch(); //构造地点查询类

                var infoWindow = new AMap.AdvancedInfoWindow({});
                map.on("hotspotover", function (result) {
                placeSearch.getDetails(result.id, function (status, result) {
                    if (status === "complete" && result.info === "OK") {
                    console.log(1);
                    placeSearch_CallBack();
                    }
                });
                });
                //回调函数
                function placeSearch_CallBack() {
                //infoWindow.open(map, result.lnglat);
                //var poiArr = data.poiList.pois;
                var location = [121.740318, 31.054126];

                infoWindow.setContent(createContent());
                infoWindow.open(map, location);
                }
                //添加点标记
                let markList = []
                self.optionsData.spaces.forEach(item=>{
                    // console.log(item,'item,,,')
                    // bgps_gps(item.longitude, item.latitude)
                    markList.push([bgps_gps(item.longitude, item.latitude).lng, bgps_gps(item.longitude, item.latitude).lat,item.spaceName,item.spaceId])
                })
                let markers = []
                console.log(markList)
                for (var i = 0; i < markList.length; i += 1) {
                    let marker;
                    let icon = new AMap.Icon({
                        image: "https://z3.ax1x.com/2021/05/20/goUkQI.png",
                        size: new AMap.Size(48, 48),
                    });
                    // let clickIcon = new AMap.Icon({
                    //     image: "",
                    //     size: new AMap.Size(48, 48), //图标大小
                    // });
                    marker = new AMap.Marker({
                        icon: icon,
                        position: markList[i],
                        offset: new AMap.Pixel(-12,-12),
                        zIndex: 101,
                        title:markList[i][2],
                        map: map,
                        spaceId: markList[i][3]
                    });
                    map.add(marker);
                    // marker逐一push到markers
                    markers.push(marker)
                    marker.on('click', resetMap);
                }
                let preIcon = new AMap.Icon({
                    image: "https://z3.ax1x.com/2021/05/20/goUUkF.png",
                    size: new AMap.Size(48, 48),
                });
                for (var i = 0; i < markers.length; i++) {
                    let a = markers[i].getPosition().R.toString().substring(0,markers[i].getPosition().R.toString().length -2 )
                    let z = markers[i].getPosition().Q.toString().substring(0,markers[i].getPosition().Q.toString().length -2 )
                    let b = center.lng.toString().substring(0,center.lng.toString().length-2)
                    let c = center.lat.toString().substring(0,center.lat.toString().length-2)
                    if(a == b && z == c){
                        markers[i].setIcon(preIcon);
                        markers[i].setLabel({//label默认蓝框白底左上角显示，样式className为：amap-marker-label
                            offset: new AMap.Pixel(-60, -25),//修改label相对于maker的位置
                            content: self.storeList[0].spaceName
                        });
                    }
                }
                //鼠标点击事件,设置地图中心点及放大显示级别
                function resetMap(e) {
                    getStoresvs({
                        projectId: self.options.storeProjectId, //this.options.projectId,
                        spaceIds: self.options.spaceId + ',' + e.target.w.spaceId//this.options.spaceId
                    }).then(val=>{
                        self.storeList = val.data
                    })
                    let preIcon = new AMap.Icon({
                        image: "https://z3.ax1x.com/2021/05/20/goUUkF.png",
                        size: new AMap.Size(48, 48),
                    });
                    let icon = new AMap.Icon({
                        image: "https://z3.ax1x.com/2021/05/20/goUkQI.png",
                        size: new AMap.Size(48, 48),
                    });
                    map.setZoomAndCenter(15, e.target.getPosition());
                    for (var i = 0; i < markers.length; i++) {
                        // let a = markers[i].getPosition()
                        let a = markers[i].getPosition().R.toString().substring(0,markers[i].getPosition().R.toString().length -2 )
                        let z = markers[i].getPosition().Q.toString().substring(0,markers[i].getPosition().Q.toString().length -2 )
                        let b = center.lng.toString().substring(0,center.lng.toString().length-2)
                        let c = center.lat.toString().substring(0,center.lat.toString().length-2)
                        if(a == b && z == c){
                        }else{
                            // console.log(markers[i])
                            // console.log(e.target,'eeeeeeeee')
                            markers[i].setIcon(icon);
                            if(markers[i].w.spaceId == e.target.w.spaceId){
                                markers[i].setIcon(preIcon);
                            }
                        }
                    }
                    // e.target.setIcon(clickIcon);
                }
                function createContent() {
                //信息窗体内容
                var s = [];
                s.push(
                    '<div class="info-title">aaaaaaa</div><div class="info-content">' +
                    "地址：bbbbbbbb"
                );
                s.push("电话：999999999");
                s.push("类型：88888888");
                s.push("<div>");
                return s.join("<br>");
                }
            };
        },
    },
    mounted(){
        let _this = this
        setTimeout(function(){
            _this.initMap()
        },500)
    }
};
</script>

<style lang="scss" scoped>

    .project_overview_periphery_right_main {
        background: rgba(78, 87, 170, 0.1);
        border-radius: 6px;
    }

    .project_overview_periphery_right_main_item {
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    font-size: 14px;
    color: #333333;
    }

    .project_overview_periphery_right_main_centent {
        background: rgba(255, 255, 255, 0.2);
    }

    .project_overview_periphery_right_main_centent_item {
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    font-size: 14px;
    color: #333333;
    }

  .project_overview_periphery_left_mapcontent {
    position: relative;       
    height:100vh;
  }
  #yzMap {
    position: absolute;
  }
  .search {
    position: absolute;
    top:calc(23px + 7vh);
    left:calc(61px + 7vh);
    z-index:999;
  }
  .search /deep/ .el-input {
    box-shadow: 0px 4px 4px 0px rgba(165, 165, 165, 0.3);
  }
  .search /deep/ .el-input__inner{
    border-radius: 8px !important;
    border:none !important;
  }
  .blobProp {
    background: #FFFFFF;
    box-shadow: 0px 6px 6px 0px rgba(117, 117, 117, 0.5);
    border-radius: 15px;
    padding:31px 26px 45px 21px;
    position: absolute;
    bottom: 6px;
    right: 21px;
    z-index:999;
  }
  .titleBox {
      display:flex;
      justify-content: space-between;
      align-items: center;
      width:635px;
  }
  .titleBox p{
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
  }
  .textBox {
      display: flex;
      align-items: center;
  }
  .textBox span{
      font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
</style>