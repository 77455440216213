import request from "@/utils/request";

export function getInfo(data) { //门店检索
  return request({
    url: "/jxzy/space/getInfo",
    method: "post",
    data
  });
}
export function getProvince() { //省检索
  return request({
    url: "/jxzy/space/getProvince",
    method: "post",
  });
}
export function getCity(data) { //市检索
  return request({
    url: "/jxzy/space/getCity",
    method: "post",
    data
  });
}
export function getCounty(data) { //区县检索
  return request({
    url: "/jxzy/space/getCounty",
    method: "post",
    data
  });
}

export function getInfoDetails(data) { //获取门店详情
  return request({
    url: "/jxzy/spaceAnal/detail",
    method: "post",
    data
  });
}

export function getInfoUserAnal(data) { //获取门店人员分析
  return request({
    url: "/jxzy/spaceAnal/userAnal",
    method: "post",
    data
  });
}

export function getStoresvs(data) { //获取门店对比
  return request({
    url: "/jxzy/spaceAnal/contrast",
    method: "post",
    data
  });
}



export function getStoreTendency(data) { //获取门店对比
  return request({
    url: "/jxzy/spaceAnal/general",
    method: "post",
    data
  });
}


export function getProjectAnal(data, pageNum, pageSize) { //获取门店对比
  return request({
    url: "/jxzy/projectAnal/list?pageNum=" + pageNum + '&pageSize=' + pageSize,
    method: "post",
    data
  });
}


export function listExport(data) { //导出项目分析列表
  return request({
    url: "/jxzy/projectAnal/listExport",
    method: "post",
    responseType: "blob",
    data,
  });
}

export function getIndexAll(data) { //项目分析详情指标总览
  return request({
    url: "/jxzy/projectAnal/indexAll",
    method: "post",
    data
  });
}


export function getUserOnLine(data) { //项目分析详情促销员在岗统计图
  return request({
    url: "/jxzy/projectAnal/userOnLine",
    method: "post",
    data
  });
}

export function getGeneral(data) { //项目分析详情概况分析趋势
  return request({
    url: "/jxzy/projectAnal/general",
    method: "post",
    data
  });
}

export function getProjectCount(data) { //项目分析详情关键词匹配度
  return request({
    url: "/jxzy/scriptLibraryGet/projectCount",
    method: "post",
    data
  });
}


export function getImportTask(pageNum, pageSize) { //获取导入列表
  return request({
    url: "/jxzy-report/importTask/page?pageNum=" + pageNum + '&pageSize=' + pageSize,
    method: "post",
  });
}

export function getDicOptions(data) { //获取字典值
  return request({
    url: "/jxzy-data/dic/options",
    method: "post",
    data
  });
}

export function getimportTaskSave(data) { //导入内容提交
  return request({
    url: "/jxzy-report/importTask/save",
    method: "post",
    data
  });
}

export function getExportTask(pageNum, pageSize) { //获取导入列表
  return request({
    url: "/jxzy-report/exportTask/page?pageNum=" + pageNum + '&pageSize=' + pageSize,
    method: "post",
  });
}

export function getExportTaskInsert(data) { //导入内容提交
  return request({
    url: "/jxzy-report/exportTask/insert",
    method: "post",
    data
  });
}

export function getProjectAnaldetail(data) { //项目详情
  return request({
    url: "/jxzy/projectAnal/detail",
    method: "post",
    data
  });
}

export function getProjectAnalindexWork(data) { //项目详情
  return request({
    url: "/jxzy/projectAnal/indexWork",
    method: "post",
    data
  });
}

export function getProjectAnalIndexBehavior(data) { //行为指标
  return request({
    url: "/jxzy/projectAnal/indexBehavior",
    method: "post",
    data
  });
}

export function getProjectAnalIndexVerbal(data) { //话术指标
  return request({
    url: "/jxzy/projectAnal/indexVerbal",
    method: "post",
    data
  });
}


export function getProjectAnalUserEfficacy(data) { //话术指标
  return request({
    url: "/jxzy/projectAnal/userEfficacy",
    method: "post",
    data
  });
}


export function getProjectAnalUserVerbal(data) { //话术指标
  return request({
    url: "/jxzy/projectAnal/userVerbal",
    method: "post",
    data
  });
}

export function getDicRemark(data) { //话术指标
  return request({
    url: "/jxzy/dicRemark/content",
    method: "post",
    data
  });
}

export function getSpaceAnal(data) { //门店行为指标
  return request({
    url: "/jxzy/spaceAnal/behavior",
    method: "post",
    data
  });
}

export function getSpaceAnalVerbal(data) { //门店行为指标
  return request({
    url: "/jxzy/spaceAnal/verbal",
    method: "post",
    data
  });
}

export function getProjectSpider(data) { //蛛网图项目人均
  return request({
    url: "/jxzy/spaceAnal/projectSpider",
    method: "post",
    data
  });
}

export function getSpaceSpider(data) { //蛛网图门店人均
  return request({
    url: "/jxzy/spaceAnal/spaceSpider",
    method: "post",
    data
  });
}
export function getSpaceMapList(data) { //门店撒点
  return request({
    url: "/jxzy/projectAnal/spacesPoint",
    method: "post",
    data
  });
}
export function getCompleteness(data) { //话术完成率
  return request({
    url: "/jxzy/projectAnal/completeness",
    method: "post",
    data
  });
}
export function getProIndexAll(data) { //指标总览
  return request({
    url: "/jxzy/projectAnal/indexAll",
    method: "post",
    data
  });
}