<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      status-icon
      :rules="loginRules"
      :model="loginForm"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <!-- <h3 class="title">
          <img :src="getImgUrl('loginLogo')" style="height: 9.4vh" alt />
        </h3> -->
      </div>
      <div class="formWrap">
        <div class="wholeform">
          <el-form-item label="账号:" prop="username">
            <span class="svg-container">
              <img :src="getImgUrl('username')" alt="" />
            </span>
            <el-input
              ref="username"
              v-model="loginForm.username"
              placeholder="请输入用户名"
              name="username"
              type="text"
              tabindex="1"
              autocomplete="on"
            />
          </el-form-item>
          <el-form-item prop="password" label="密码:">
            <span class="svg-container">
              <img :src="getImgUrl('password')" alt="" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              placeholder="请输入6-12位密码"
              name="password"
              :type="passwordType"
              tabindex="1"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <!-- <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" /> -->
            </span>
          </el-form-item>
          <el-checkbox v-model="autoLogin">自动登录</el-checkbox>
          <el-button
            :loading="loading"
            type="primary"
            class="loginBtn"
            @click.native.prevent="handleLogin"
            >登陆</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
// import { validUsername } from "@/utils/validate";
import Tool from "@/utils/tool.js";
import sha1 from "js-sha1";
import {
  setToken,
  setNickName,
  setUserName,
  setPassword,
} from "@/utils/auth.js";
import { login } from "@/api/login.js";

// const validateUsername = (rule, value, callback) => {
//   if (!validUsername(value)) {
//     callback(new Error("用户名错误"));
//   } else {
//     callback();
//   }
// };
const validatePassword = (rule, value, callback) => {
  if (value.length < 1) {
    callback(new Error("密码长度至少1位"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      passwordType: "password",
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      autoLogin: true,
      loading: false,
    };
  },
  mixins: [Tool],
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    //登录
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let sha1 = require("js-sha1");
          let loginUser = { ...this.loginForm };
          loginUser.password = sha1(this.loginForm.password);
          loginUser.type = 0;
          login(loginUser)
            .then(({ data }) => {
              setToken(data.access_token);
              setNickName(data.loginUser.sysUser.nickName);
              setUserName(loginUser.username);
              setPassword(loginUser.password);
              this.$store.commit("SET_MENU", []);
              // location.reload();
              this.$router.push(this.$route.query.redirect || "/home");
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    // getOtherQuery(query) {
    //   return Object.keys(query).reduce((acc, cur) => {
    //     if (cur !== "redirect") {
    //       acc[cur] = query[cur];
    //     }
    //     return acc;
    //   }, {});
    // },
  },
  // watch: {
  //   $route: {
  //     handler: function (route) {
  //       const query = route.query;
  //       if (query) {
  //         this.redirect = query.redirect;
  //         this.otherQuery = this.getOtherQuery(query);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>
<style lang="scss">
@import "../../assets/common.scss";
</style>
<style lang="scss">
$light_gray: #fff;
$cursor: #fff;
$blue: #4e57aa;
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 6vh;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      // padding: 6px 0.26vw 6px 0.78125vw;
      padding: 0 0.26vw 0 0.78125vw;
      height: 6vh;
      line-height: 6vh;
      color: #cccccc;
      font-size: calc(24vw / 19.2);

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px transparent inset !important;
      }
    }
  }

  .el-form-item {
    // border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    display: flex;
    align-items: center;
  }
  .el-form-item__content {
    display: flex;
    border: 1px solid #d2dbe5;
    box-shadow: 0 2px 4px 0 #eff2f7;
    border-radius: 8px;
    width: 65%;
    margin-top: 1vh;
  }

  .el-form-item__label {
    margin-top: 1vh;
    height: 6.18vh;
    line-height: 6.18vh !important;
    font-family: PingFangSC-Regular;
    font-size: calc(24vw / 19.2);
    color: $blue;
    margin-left: 1vw;
    letter-spacing: 10px;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: "";
  }
  .el-checkbox:last-of-type {
    float: right;
    margin-right: 3vw;
  }
  .el-checkbox__label {
    font-size: calc(14vw / 19.2);
    line-height: calc(14vw / 19.2);
  }
  .el-checkbox__inner {
    min-width: 14px;
    min-height: 14px;
    width: calc(14vw / 19.2);
    height: calc(14vh / 10.8);
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    color: $blue;
    background: $blue;
    border-color: $blue;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #999;
  }
  .el-form-item--small .el-form-item__error {
    padding-top: 1vh;
  }
}
</style>

<style lang="scss" scoped>
$dark_gray: #889aa4;
$light_gray: #eee;
$white: #fff;
$blue: #4e57aa;
.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.9;
  background-image: url("https://pic.imgdb.cn/item/6063f3ea8322e6675c16d4a2.png"),
    linear-gradient(
      180deg,
      rgba(27, 37, 123, 0.8) 1%,
      rgba(0, 24, 71, 0.3) 100%
    );
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: justify;
  .login-form {
    position: relative;
    width: 33%;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .formWrap {
      background: rgba(133, 189, 255, 0.2);
      border-radius: 8px;
      padding: 2vh 1vw;
      .wholeform {
        background: $white;
        border-radius: 8px;
        padding: 14% 5%;
        .loginBtn {
          width: 50%;
          margin: 3vh 25% 0;
          height: 6.5vh;
          padding: 0;
          background: $blue;
          color: $white;
          border-radius: 5vw;
          font-family: PingFangSC-Medium;
          font-size: calc(28vw / 19.2);
          color: #ffffff;
          letter-spacing: 5px;
          font-weight: bold;
        }
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    position: relative;
    padding: 0 0.26vw 0 0.78125vw;
    // box-sizing: border-box;
    color: $dark_gray;
    // vertical-align: middle;
    width: calc(30vw / 19.2);
    display: inline-block;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: calc(24vw / 19.2);
      height: calc(27vh / 10.8);
      // margin-top: 12.5px;
      // margin: 12.5px 0;
      // vertical-align: middle;
    }
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 8vh auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
