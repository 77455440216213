<!-- 基础数据 -->
<template>
  <div class="dataBox">
    <div class="title">基础数据</div>
    <div class="lists">
      <div class="number" @click="listChange(1)">
        <div class="icon">
          <img src="../../../assets/img/mdIcon.png" alt="" />
        </div>
        <div class="num">{{ lists.spaceCount }}</div>
        <p>门店数量</p>
      </div>
      <div class="number" @click="listChange(2)">
        <div class="icon">
          <img src="../../../assets/img/sjIcon.png" alt="" />
        </div>
        <div class="num">{{ lists.userCount }}</div>
        <p>销售代表</p>
      </div>
      <div class="number" @click="listChange(3)">
        <div class="icon">
          <img src="../../../assets/img/xdIcon.png" alt="" />
        </div>
        <div class="num">{{ lists.patrolSpace }}</div>
        <p>总巡店数</p>
      </div>
      <div class="number" @click="listChange(4)">
        <div class="icon">
          <img src="../../../assets/img/ryIcon.png" alt="" />
        </div>
        <div class="num">{{ lists.userAvgPatrolSpace }}</div>
        <p>人员平均巡店数</p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseInfo } from "@/api/dp/dphead";
export default {
  name: "Vue",
  components: {},
  props: {
    projectId: String,
    date: Array,
    placeCode: String,
  },
  data() {
    return {
      lists: [],
      lastProjectId: "",
      code: "",
    };
  },
  watch: {
    placeCode: {
      handler(val) {
        this.code = val;
        this.init();
      },
    },
    projectId: {
      handler(val) {
        this.lastProjectId = val;
        this.init();
      },
    },
    lastDate: {
      handler(val) {
        this.init();
      },
    },
  },
  computed: {
    lastDate() {
      return this.date;
    },
  },
  methods: {
    listChange(num) {},
    init() {
      let option = {
        projectId: this.lastProjectId,
        startDate: this.lastDate[0],
        endDate: this.lastDate[1],
        provinceCode: this.code,
      };
      baseInfo(option).then((res) => {
        if (res && res.code == 200) {
          this.lists = res.data;
        }
      });
    },
  },
  created() {},
  mounted() {
    // console.log(this.projectId);
  },
};
</script>
<style lang="less" scoped>
.dataBox {
  width: 420px;
  height: 325px;
  background-image: url(../../../assets/img/rightList.png);
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    padding-left: 30px;
    font-family: "YouSheBiaoTiHei";
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .lists {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin-top: 80px;
    .number {
      width: 91px;
      height: 200px;
      background-image: url(../../../assets/img/dataDi.png);
      background-position: 0 0;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      cursor: pointer;
      .icon {
        width: 59px;
        height: 59px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .num {
        font-weight: 700;
        font-size: 24px;
        color: #ffb14d;
        // margin: 10px 0 5px 0;
      }
      p {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
  }
}
</style>