import Vue from "vue"
import store from "@/store"
Vue.directive("hasPremi", {
  inserted: function(el, binding) {
    store.getters.permissions.forEach((item) => {
      if (item.menuName === binding.arg) {
        if (item.children.length) {
          if (!item.children.includes(el.innerText)) {
            el.parentNode && el.parentNode.removeChild(el)
          }
        } else {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    })
  }
})
