<template>
  <div class="i_header">
    <!-- <div class="i_header_left" @click="goHome()"> -->
    <!-- <img
        src="../../../assets/pc/小logo .png"
        alt=""
        style="zoom: 1.3;margin-right:3.3vw"
        v-if="!isCollapse"
      /> -->
    <div
      class="i_header_logo"
      :class="isCollapse ? 'collapse_header_logo' : ''"
    >
      <img
        v-show="!isCollapse"
        class="header_open_logo"
        src="../../../assets/pc/fold-logo.png"
        alt=""
        @click="goHome()"
      />
      <img
        v-show="isCollapse"
        class="header_collapse_logo"
        src="../../../assets/pc/jxzy.png"
        alt=""
        @click="goHome()"
      />
    </div>
    <img
      class="fold_img"
      src="../../../assets/pc/more.png"
      alt="fold"
      @click="changeSidebar"
    />
    <!-- </div> -->
    <div class="i_header_right">
      <!-- <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <i
        class="el-icon-search"
        style="
          display: inline-block;
          margin-left: 1.5vw;
          margin-right: 1.5vw;
          font-weight: 700;
        "
      ></i>
      <img src="../../../assets/pc/消息.png" alt="" srcset="" /> -->
      <div class="i_header_right_user">
        <div class="block">
          <el-avatar
            size="medium"
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
          ></el-avatar>
        </div>
        <!-- <img src="../../../assets/pc/username.png" alt="" srcset=""> -->
        <el-dropdown style="cursor: pointer">
          <span style="margin-left: 8px">
            {{ nickName }}<i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  getNickName,
  removeToken,
  removeNickName,
  removeUserName,
  removePassword,
} from "@/utils/auth.js";
import { logout } from "@/api/login.js";
export default {
  name: "headerindex",
  components: {},
  computed: {
    ...mapGetters(["sidebar", "isCollapse"]),
    // ...mapState(["isCollapse"]),
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
    // isCollapse() {
    //   return !this.sidebar.opened;
    // },
  },
  data() {
    return {
      nickName: getNickName(),
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "上海雀巢项目",
    };
  },
  mounted() {},

  methods: {
    //退出登录
    logout() {
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        logout().then(() => {
          removeToken();
          removeUserName();
          removePassword();
          removeNickName();
          // location.reload();
          this.$router.push("/login");
        });
      });
    },
    changeSidebar() {
      this.$store.commit("app/changeSidebar", !this.isCollapse);
    },
    toggleSideBar() {
      console.log("toggle");
      this.$store.dispatch("app/toggleSideBar");
    },
    goHome() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped lang="less">
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.i_header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 7vh;
  background: #fff;
  // padding: 0vh 3vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
}

.i_header_left {
  width: 30%;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.i_header_logo {
  width: 16vw;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.collapse_header_logo {
  width: 6vw;
}
.header_open_logo {
  height: 4.7vh;
  // margin-right: 1vw
}
.header_collapse_logo {
  height: 4.7vh;
}
.fold_img {
  height: 3.7vh;
  cursor: pointer;
  margin-left: 1.6vw;
}
.i_header_right {
  // width: 60%;
  position: absolute;
  right: 3vw;
  height: 7vh;
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  // align-items: center;
  // align-self: flex-end;
}

.i_header_right_user {
  width: 170px;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .block {
    width: 5vh;
    height: 5vh;
    .el-avatar--medium {
      width: 5vh;
      height: 5vh;
    }
  }
}
</style>
