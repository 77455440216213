import request from "@/utils/request";
// 字典
export function dicOptions(data) {
    return request({
        url: "/jxzy-data/dic/options",
        method: "post",
        data,
    });
}
// 分页查询项目列表
export function larProjects(data, pageNum, pageSize) {
    return request({
        url: "/jxzy/largeScreen/projects",
        method: "post",
        data,
        params: { pageNum, pageSize }
    });
}
// 获取全国各省份话术完整度分布情况
export function provinceCompleteness(data) {
    return request({
        url: "/jxzy/largeScreen/provinceCompleteness",
        method: "post",
        data,
    });
}
// 门店TOP20
export function spaceTop(data) {
    return request({
        url: "/jxzy/largeScreen/spaceTop",
        method: "post",
        data,
    });
}
// 人员TOP20
export function userTop(data) {
    return request({
        url: "/jxzy/largeScreen/userTop",
        method: "post",
        data,
    });
}
// 查询项目基础信息
export function baseInfo(data) {
    return request({
        url: "/jxzy/largeScreen/baseInfo",
        method: "post",
        data,
    });
}
// 项目话术完成度
export function completeness(data) {
    return request({
        url: "/jxzy/largeScreen/completeness",
        method: "post",
        data,
    });
}
// 热词分析
export function verbalTrickGet(data) {
    return request({
        url: "/jxzy/largeScreen/verbalTrickGet",
        method: "post",
        data,
    });
}
// 门店撒点
export function spacePoint(data) {
    return request({
        url: "/jxzy/largeScreen/spacePoint",
        method: "post",
        data,
    });
}
