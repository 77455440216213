<template>
  <div class="loginBox">
    <!-- 搜索 -->
    <div class="loginTop">
      <div
        class="i_header_logo"
        :class="isCollapse ? 'collapse_header_logo' : ''"
      >
        <img
          v-show="!isCollapse"
          class="header_open_logo"
          src="@/assets/pc/fold-logo.png"
          alt=""
          @click="goHome()"
        />
        <img
          v-show="isCollapse"
          class="header_collapse_logo"
          src="@/assets/pc/jxzy.png"
          alt=""
          @click="goHome()"
        />
      </div>

      <div class="searchBox">
        <div class="searchRe">
          <input
            type="text"
            class="search"
            placeholder="搜索项目"
            v-model="searchText"
          />
          <div class="searchBtn" @click="searcch">搜 索</div>
        </div>
      </div>
      <div class="selectedBox">
        <!-- 已选项目 -->
        <div class="selectLists">
          <div class="title">已选项目：</div>
          <span class="list-name">{{ projectName }}</span>
        </div>
        <!-- 确认按钮 -->
        <div class="toMore" @click="toMore">确认查看</div>
      </div>
    </div>
    <!-- 所有项目 -->
    <div class="allBox">
      <div class="title"></div>
      <div class="title">
        <p>排序：</p>
        <div
          v-for="(item, index) in typeList"
          :key="index"
          @click="typeChange(item.statusCode)"
        >
          <span :class="item.statusCode == typeCode ? 'activeCode' : ''">{{
            item.statusValue
          }}</span>
          <img src="../../assets/img/paixu.png" alt="" />
        </div>
        <!-- <div>
          <span>名称排序</span>
          <img src="../../assets/img/paixu.png" alt="" />
        </div> -->
      </div>
      <!-- 项目排版 -->
      <div class="listsContent">
        <ul>
          <li
            v-for="(item, index) in dataList"
            :key="index"
            @click="checkData(item, index)"
            :class="projectId == item.projectId ? 'activeBck' : ''"
          >
            <div class="initIcon">
              <img
                src="../../assets/img/initIcon.png"
                alt=""
                v-if="projectId != item.projectId"
              />
              <img
                src="../../assets/img/checkIcon.png"
                alt=""
                v-if="projectId == item.projectId"
              />
            </div>
            <div>{{ item.projectName }}</div>
          </li>
        </ul>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { dicOptions, larProjects } from "@/api/dp/dphead";
export default {
  name: "login",
  components: {},
  props: {},
  data() {
    return {
      checked: false,
      changeIndex: 0,
      currentPage: 1,
      total: 100,
      pageSize: 10,
      typeList: [],
      dataList: [],
      searchText: "",
      typeCode: "",
      projectName: "",
      projectId: "",
    };
  },
  watch: {
    checked: {
      handler(val) {
        // console.log(val);
      },
    },
  },
  computed: {},
  methods: {
    goHome() {
      this.$router.push("/home");
    },
    // 搜索
    searcch() {
      this.getData();
    },
    // 字典获取排序类型
    initOpation() {
      let option = {
        setCode: "SORT_TYPE",
      };
      dicOptions(option).then((res) => {
        if (res && res.code == 200) {
          this.typeList = res.data;
        }
      });
    },
    // 项目列表
    getData() {
      let option = {
        orderByCode: this.typeCode,
        projectName: this.searchText,
      };
      let pageNum = this.currentPage;
      let pageSize = this.pageSize;
      larProjects(option, pageNum, pageSize).then((res) => {
        if (res && res.code == 200) {
          this.dataList = res.rows;
          this.total = res.total;
          if (this.dataList[0]) {
            this.projectName = this.dataList[0].projectName;
            this.projectId = this.dataList[0].projectId;
          } else {
            this.projectName = "暂无";
            this.projectId = "";
          }
        }
      });
    },
    // 类型选择
    typeChange(id) {
      // this.typeCode = id;
      let changeId = id;
      if (this.typeCode == changeId) {
        this.typeCode = "";
      } else {
        this.typeCode = changeId;
      }
      this.getData();
    },
    // 选择项目
    checkData(item, index) {
      this.changeIndex = index;
      this.projectName = item.projectName;
      this.projectId = item.projectId;
    },
    // 跳转大屏
    toMore() {
      let id = this.projectId;
      this.$router.push({ path: "/nextIndex?id=" + id });
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
  created() {},
  mounted() {
    this.initOpation();
    this.getData();
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
input:focus {
  outline: none;
}
.loginBox {
  //   width: 1920px;
  height: 1080px;
  width: 100%;
  //   height: 100%;
  background: radial-gradient(
    49.45% 49.45% at 50% 102.2%,
    #281442 0%,
    #010117 100%
  );
  .loginTop {
    width: 100%;
    height: 384px;
    background-image: url(../../assets/img/initlogin.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    .searchBox {
      height: 87%;
      display: flex;
      align-items: center;
      justify-content: center;
      .searchRe {
        position: relative;
      }
      .search {
        width: 900px;
        height: 56px;
        background: #ffffff;
        opacity: 0.9;
        border: 3px solid rgba(159, 163, 255, 0.4);
        border-radius: 50px;
        font-size: 20px;
        padding-left: 20px;
        color: #949090;
      }
      .searchBtn {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 150px;
        height: 56px;
        background: #6d4edb;
        cursor: pointer;
        text-align: center;
        line-height: 56px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        // border: 3px solid rgba(159, 163, 255, 0.4);
      }
    }
  }
  //   已选项目
  .selectedBox {
    // height: 15%;
    padding: 0 160px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    line-height: 48px;
    .selectLists {
      display: flex;
      justify-content: flex-start;
      .title {
        font-size: 18px;
        color: #fdfdfd;
      }
      .list-name {
        box-sizing: border-box;
        padding: 10px 26px;
        border: 1px solid #6d4edb;
        line-height: 44px;
        border-radius: 50px;
        font-size: 16px;
        line-height: 24px;
        margin-left: 20px;
        background: rgba(84, 41, 205, 0.2);
      }
    }
    .toMore {
      width: 195px;
      height: 47px;
      background-image: url(../../assets/img/toMore.png);
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.allBox {
  padding: 0 160px;
  margin-top: 30px;
  .title {
    display: flex;
    justify-content: flex-start;
    color: #ffffff;
    line-height: 30px;
    font-weight: 600;
    p {
      width: 80px;
      margin: 0;
      padding: 0;
      text-align: right;
      margin-bottom: 17px;
    }
    div {
      display: flex;
      justify-content: flex-start;
      margin-left: 30px;
      cursor: pointer;
      span {
        color: #fdfdfd;
        font-size: 16px;
        font-weight: normal;
      }
      img {
        width: 18px;
        height: 18px;
        padding-top: 5px;
      }
    }
  }
}
/deep/.el-checkbox {
  margin-left: 30px;
  //从这里开始 就是更改颜色的代码，将你的颜色           改掉我的颜色就可以了
  .el-checkbox__label {
    color: #fff !important;
    font-size: 16px;
  }
  .el-checkbox__input {
    margin-bottom: px(5);
    &.is-checked {
      .el-checkbox__inner {
        background-color: #6d4edb;
        border-color: #6d4edb;
      }
    }
    .el-checkbox__inner {
      &:hover {
        border-color: #6d4edb;
      }
    }
  }
}
li {
  list-style: none;
}
// 项目排版
.listsContent {
  color: #fff;
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      width: 296px;
      height: 236px;
      background-image: url(../../assets/img/initlist.png);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      margin-bottom: 30px;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
      margin-right: 20px;
      .initIcon {
        width: 88px;
        height: 88px;
        margin: 0 auto;
        margin-top: 32px;
        margin-bottom: 13px;
        img {
          width: 100%;
        }
      }
    }
    .activeBck {
      background-image: url(../../assets/img/checklist.png);
    }
  }
}
.block {
  width: 100%;
  text-align: center;
}
.activeCode {
  color: #6d4edb !important;
}
.i_header_logo {
  width: 16vw;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.collapse_header_logo {
  width: 6vw;
}
.header_open_logo {
  height: 4.7vh;
  // margin-right: 1vw
}
.header_collapse_logo {
  height: 4.7vh;
}
.fold_img {
  height: 3.7vh;
  cursor: pointer;
  margin-left: 1.6vw;
}
</style>