import router from "@/router"
import { getToken } from "@/utils/auth"
import store from "@/store"

function getPermissions(menus, to) {
  menus.forEach((item) => {
    if (item.children.length !== 0) {
      if (item.menuType === "menu") {
        if (item.menuName === to.meta.title) {
          to.meta.show = true
          to.meta.menuId = item.menuId
        } else {
          getPermissions(item.children, to)
        }
      } else if (item.menuType === "button") {
        if (item.parentId === to.meta.menuId) {
          let buttons = []
          buttons.push(item.menuName)
          to.buttons = buttons
        } else {
          getPermissions(item.children, to)
        }
      }
    }
  })
}

router.beforeEach((to, from, next) => {
  const token = getToken()
  if (token) {
    if (store.getters.menus.length === 0) {
      store.dispatch("getInfo").then((res) => {
        if (to.meta.title) {
          if (res.includes(to.meta.title)) {
            next()
          }
        } else {
          next()
        }
      })
    } else {
      if (to.meta.title) {
        if (store.getters.menus.includes(to.meta.title)) {
          next()
        }
      } else {
        next()
      }
    }
  } else {
    if (to.path == "/login") {
      next()
    } else {
      next({
        path: "/login",
        replace: true,
        query: { redirect: to.fullPath }
      })
    }
  }
})
