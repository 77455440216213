// collection.js
// import Axios from "axios";
// import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from "@/utils/auth";
// import common from '../../utils/urls'
const state = {
  // getDataUrl: common.uris,
  token: getToken(),
  name: "",
  avatar: "",
  introduction: "",
  roles: [],
};
const getters = {};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    console.log(state.token);
  },
};

//异步登录
const actions = {
  // user login
  //ditudaiwnei
  //   async dituToken({commit}){
  //     //发送请求相关的数据
  //     const {resultCode,resultData} = await axios.get("/exchange/exam/selectStore").then(res =>{
  //       console.log(res.resultData);
  //     });
  //     console.log(resultData);
  //     if(resultCode === 1000){
  //         commit("dituToken",resultData)
  //     }
  // }
};
export default {
  namespaced: true, // 用于在全局引用此文件里的方法时标识这一个的文件名
  state,
  getters,
  mutations,
  actions,
};
