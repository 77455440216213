import request from '@/utils/request';

export function userAnalList(data, pageNum, pageSize) {
  return request({
    url: '/jxzy/userAnal/list',
    method: 'post',
    data,
    params: { pageNum, pageSize }
  });
}

export function userAnalDetail(data) {
  return request({
    url: '/jxzy/userAnal/detail',
    method: 'post',
    data
  });
}

export function spaceAvg(projectId, spaceId, startDate, endDate) {
  return request({
    url: '/jxzy/userAnal/spaceAvg',
    method: 'post',
    data: { projectId, spaceId, startDate, endDate }
  });
}

export function projectAvg(projectId, startDate, endDate) {
  return request({
    url: '/jxzy/userAnal/projectAvg',
    method: 'post',
    data: { projectId, startDate, endDate }
  });
}

export function userAvg(startDate, endDate, userId) {
  return request({
    url: '/jxzy/userAnal/userAvg',
    method: 'post',
    data: { startDate, endDate, userId }
  });
}

export function listExport(data) {
  return request({
    url: '/jxzy/userAnal/listExportCsv',
    method: 'post',
    responseType: 'blob',
    data
  });
}

export function projectList() {
  return request({
    url: '/jxzy/project/list',
    method: 'post'
  });
}

export function getSpaces(projectId) {
  return request({
    url: '/jxzy/space/getSpaces',
    method: 'post',
    data: { projectId }
  });
}

export function scriptLibraryGet(userId) {
  return request({
    url: '/jxzy/scriptLibraryGet/count',
    method: 'post',
    data: { userId }
  });
}

export function getStoreList(data, pageNum, pageSize) {
  return request({
    url: '/jxzy/spaceAnal/list?pageNum=' + pageNum + '&pageSize=' + pageSize,
    method: 'post',
    data
  });
}

export function spaceAnalExport(data) {
  //导出门店列表
  return request({
    url: '/jxzy/spaceAnal/listExport',
    method: 'post',
    responseType: 'blob',
    data
  });
}

export function listExportSG(data) {
  //导出关键词明细
  return request({
    url: '/jxzy/userAnal/listExportSG',
    method: 'post',
    responseType: 'blob',
    data
  });
}

export function listExportF(data) {
  //导出再排明细
  return request({
    url: '/jxzy/userAnal/listExportF',
    method: 'post',
    responseType: 'blob',
    data
  });
}

export function listExportP(data) {
  //导出再排明细分词
  return request({
    url: '/jxzy/userAnal/listExportP',
    method: 'post',
    responseType: 'blob',
    data
  });
}
// 多条件查询轨迹人员列表
export function getIocusUserList(data) {
  return request({
    url: '/jxzy/trackAnal/users',
    method: 'post',
    data
  });
}
//根据设备和时间查询人员这段时间内的移动轨迹。
export function getIcoList(data) {
  return request({
    url: '/jxzy/trackAnal/tracks',
    method: 'post',
    data
  });
}
// 查询门店撒点列表
export function getMarkerList(data) {
  return request({
    url: '/jxzy/trackAnal/spaces',
    method: 'post',
    data
  });
}

// 查询用户日期
export function getTrackDates(data) {
  return request({
    url: '/jxzy/trackAnal/dates',
    method: 'post',
    data
  });
}

// 跳转音频获得audioId
export function getAudioDayInfo(data) {
  return request({
    url: '/jxzy/audioDay/getAudioDayInfo',
    method: 'post',
    data
  });
}
