import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import dp from "./dp";

import getters from "./getters";
import app from "./modules/app";

import speechAnalysis from "./modules/speechAnalysis";
import supervision from "./modules/supervision";
import permission from "./modules/permission";

Vue.use(Vuex);

let store = new Vuex.Store({
  modules: {
    user,
    dp,
    app,
    speechAnalysis,
    supervision,
    permission
  },
  getters,
});
export default store;
