<!-- 地图 -->
<template>
  <div class="mapBox">
    <div id="container"></div>
    <div class="mapText">
      <p class="title">省份话术完成度区间分布</p>
      <ul>
        <li v-for="(item, index) in textData" :key="index">
          <span :style="{ 'background-color': item.color }"></span>
          <p>{{ item.text }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { provinceCompleteness, spacePoint } from "@/api/dp/dphead";
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "759904e592635c94e489029d9a1a3483",
};
export default {
  name: "Vue",
  components: {},
  props: {
    projectId: String,
    date: Array,
    userId: String,
    spaceId: String,
  },
  data() {
    return {
      textData: [
        {
          color: "#F65826",
          text: "话术完成度 100%-75%",
        },
        {
          color: "#FFC960",
          text: "话术完成度 75%-50%",
        },
        {
          color: "#AE46FF",
          text: "话术完成度 50%-25%",
        },
        {
          color: "#703CE9",
          text: "话术完成度 25%-0%",
        },
      ],
      map: null,
      dataArr: [],
      districtExplorer: null,
      tipMarker: null,
      $tipMarkerContent: null,
      currentAreaNode: null,
      lastProjectId: "",
      plaseCode: "",
      checkUserId: "",
      checkSpaceId: "",
      markMap: null,
    };
  },
  watch: {
    projectId: {
      handler(val) {
        this.lastProjectId = val;
        this.initColor();
      },
    },
    userId: {
      handler(val) {
        this.checkSpaceId = "";
        this.checkUserId = val;
        this.markInit();
      },
    },
    spaceId: {
      handler(val) {
        this.checkUserId = "";
        this.checkSpaceId = val;
        this.markInit();
      },
    },
    lastDate: {
      handler() {
        this.initColor();
      },
    },
  },
  computed: {
    lastDate() {
      return this.date;
    },
  },
  methods: {
    initColor() {
      let option = {
        projectId: this.lastProjectId,
        startDate: this.lastDate[0],
        endDate: this.lastDate[1],
      };
      provinceCompleteness(option).then((res) => {
        if (res && res.code == 200) {
          this.dataArr = res.data;
          this.dataArr.forEach((e) => {
            let color = "";
            if (e.completenessSort == 4) {
              // color = "#703CE9";
              color = "#F65826";
            } else if (e.completenessSort == 3) {
              // color = "#AE46FF";
              color = "#FFC960";
            } else if (e.completenessSort == 2) {
              // color = "#FFC960";
              color = "#AE46FF";
            } else {
              // color = "#F65826";
              color = "#703CE9";
            }
            e.color = color;
          });
          this.initMap();
        }
      });
    },
    markInit() {
      let _this = this;
      let option = {
        projectId: _this.lastProjectId,
        startDate: _this.lastDate[0],
        endDate: _this.lastDate[1],
        provinceCode: _this.plaseCode,
        userId: _this.checkUserId,
        spaceId: _this.checkSpaceId,
      };
      spacePoint(option).then((res) => {
        if (res && res.code == 200) {
          let markData;
          markData = [];
          markData = res.data;
          this.addMark(markData);
        }
      });
    },
    initMap(markData) {
      var _this = this;
      AMapLoader.load({
        key: "c05a7aa45839ac8f40d8e4337ab2f116", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.DistrictSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          version: "1.0",
          // plugins: [],
        },
        // plugins: ["AMap.AutoComplete"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        var map = new AMap.Map("container", {
          zoom: 18,
          center: [121.47, 31.23],
          // resizeEnable: true, //是否监控地图容器尺寸变化
          mapStyle: "amap://styles/darkblue", // 地图样式
        });
        _this.markMap = map;
        // 获取地图级别

        // 加色块
        var colorList = {};
        var GDPSpeed = {};
        var colors = [];
        _this.dataArr.forEach((e) => {
          var key = e.provinceCode.toString();
          var value = e.color;
          GDPSpeed[key] = value;
        });
        var getColorByDGP = function (adcode) {
          if (!colorList[adcode]) {
            var gdp = GDPSpeed[adcode];
            if (!gdp) {
              // colorList[adcode] = "transparent";
              colorList[adcode] = "#4C25BC";
            } else {
              colorList[adcode] = gdp;
            }
          }
          return colorList[adcode];
        };
        AMapUI.load(
          ["ui/geo/DistrictExplorer", "lib/$"],
          function (DistrictExplorer, $) {
            //创建一个实例
            var districtExplorer = (window.districtExplorer =
              new DistrictExplorer({
                eventSupport: true, //打开事件支持
                map: map,
              }));

            //当前聚焦的区域
            var currentAreaNode = null;

            //鼠标hover提示内容
            var $tipMarkerContent = $('<div class="tipMarker top"></div>');

            var tipMarker = new AMap.Marker({
              content: $tipMarkerContent.get(0),
              offset: new AMap.Pixel(0, 0),
              bubble: true,
            });

            //根据Hover状态设置相关样式
            function toggleHoverFeature(feature, isHover, position) {
              tipMarker.setMap(isHover ? map : null);

              if (!feature) {
                return;
              }

              var props = feature.properties;

              if (isHover) {
                //更新提示内容
                // $tipMarkerContent.html(props.adcode + ": " + props.name);
                $tipMarkerContent.html(props.name);
                //更新位置
                tipMarker.setPosition(position || props.center);
              }

              $("#area-tree")
                .find('h2[data-adcode="' + props.adcode + '"]')
                .toggleClass("hover", isHover);

              //更新相关多边形的样式
              var polys = districtExplorer.findFeaturePolygonsByAdcode(
                props.adcode
              );
              for (var i = 0, len = polys.length; i < len; i++) {
                polys[i].setOptions({
                  fillOpacity: isHover ? 0.8 : 0.5,
                });
              }
            }

            //监听feature的hover事件
            districtExplorer.on(
              "featureMouseout featureMouseover",
              function (e, feature) {
                toggleHoverFeature(
                  feature,
                  e.type === "featureMouseover",
                  e.originalEvent ? e.originalEvent.lnglat : null
                );
              }
            );

            //监听鼠标在feature上滑动
            districtExplorer.on("featureMousemove", function (e, feature) {
              //更新提示位置
              tipMarker.setPosition(e.originalEvent.lnglat);
            });

            //feature被点击
            districtExplorer.on("featureClick", function (e, feature) {
              // if (viaMarker) {
              // }
              var props = feature.properties;
              //如果存在子节点
              // if (props.childrenNum > 0) {
              //切换聚焦区域
              let colorDemo = getColorByDGP(props.adcode);
              if (colorDemo == "#4C25BC") {
                _this.plaseCode = "";
                _this.$emit("mapChange", "");
                switch2AreaNode(100000);
                // _this.markInit();
              } else {
                _this.plaseCode = props.adcode;
                _this.$emit("mapChange", props.adcode);
                let option = {
                  projectId: _this.lastProjectId,
                  startDate: _this.lastDate[0],
                  endDate: _this.lastDate[1],
                  provinceCode: _this.plaseCode,
                  userId: _this.checkUserId,
                  spaceId: _this.checkSpaceId,
                };
                spacePoint(option).then((res) => {
                  if (res && res.code == 200) {
                    // _this.addMark(res.data, map);
                  }
                });
                switch2AreaNode(props.adcode);
              }
              // }
            });
            //外部区域被点击
            districtExplorer.on("outsideClick", function (e) {
              districtExplorer.locatePosition(
                e.originalEvent.lnglat,
                function (error, routeFeatures) {
                  if (routeFeatures && routeFeatures.length > 1) {
                    //切换到省级区域
                    // switch2AreaNode(routeFeatures[1].properties.adcode);
                  } else {
                    //切换到全国
                    _this.plaseCode = "";
                    _this.$emit("mapChange", "");
                    switch2AreaNode(100000);
                  }
                },
                {
                  levelLimit: 2,
                }
              );
            });

            //绘制区域面板的节点
            function renderAreaPanelNode(ele, props, color) {
              var $box = $("<li/>").addClass("lv_" + props.level);

              var $h2 = $("<h2/>")
                .addClass("lv_" + props.level)
                .attr({
                  "data-adcode": props.adcode,
                  "data-level": props.level,
                  "data-children-num": props.childrenNum || void 0,
                  "data-center": props.center.join(","),
                })
                .html(props.name)
                .appendTo($box);

              if (color) {
                $h2.css("borderColor", color);
              }

              //如果存在子节点
              if (props.childrenNum > 0) {
                //显示隐藏
                $('<div class="showHideBtn"></div>').appendTo($box);

                //子区域列表
                $("<ul/>")
                  .addClass("sublist lv_" + props.level)
                  .appendTo($box);

                $('<div class="clear"></div>').appendTo($box);

                if (props.level !== "country") {
                  $box.addClass("hide-sub");
                }
              }

              $box.appendTo(ele);
            }
            //填充某个节点的子区域列表
            function renderAreaPanel(areaNode) {
              var props = areaNode.getProps();
              var $subBox = $("#area-tree")
                .find('h2[data-adcode="' + props.adcode + '"]')
                .siblings("ul.sublist");
              // 恢复全屏
              if (!$subBox.length && props.childrenNum) {
                //父节点不存在，先创建
                renderAreaPanelNode($("#area-tree"), props);
                $subBox = $("#area-tree").find("ul.sublist");
              }
              if ($subBox.attr("data-loaded") === "rendered") {
                switch2AreaNode(100000);
                return;
              }
              $subBox.attr("data-loaded", "rendered");
              var subFeatures = areaNode.getSubFeatures();
              //填充子区域
              for (var i = 0, len = subFeatures.length; i < len; i++) {
                let plaseCode = subFeatures[i].properties.adcode;
                let codeColor = getColorByDGP(plaseCode)
                  ? getColorByDGP(plaseCode)
                  : "red";
                renderAreaPanelNode(
                  $subBox,
                  areaNode.getPropsOfFeature(subFeatures[i]),
                  // colors[i % colors.length]
                  codeColor
                );
              }
            }
            //绘制某个区域的边界
            function renderAreaPolygons(areaNode) {
              //更新地图视野
              map.setBounds(areaNode.getBounds(), null, null, true);
              //清除已有的绘制内容
              districtExplorer.clearFeaturePolygons();
              //绘制子区域
              districtExplorer.renderSubFeatures(
                areaNode,
                function (feature, i) {
                  let plaseCode = feature.properties.adcode;
                  let demo = getColorByDGP(plaseCode);
                  return {
                    cursor: "default",
                    bubble: true,
                    strokeColor: "#fff", //线颜色
                    strokeOpacity: 1, //线透明度
                    strokeWeight: 1, //线宽
                    // fillColor: strokeColor, //填充色
                    fillColor: demo, //填充色
                    fillOpacity: 0.5, //填充透明度
                    // fillOpacity: 1, //填充透明度
                  };
                }
              );

              //绘制父区域
              districtExplorer.renderParentFeature(areaNode, {
                cursor: "default",
                bubble: true,
                strokeColor: "black", //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 1, //线宽
                fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
                fillOpacity: 0.5, //填充透明度
              });
            }
            //切换区域后刷新显示内容
            function refreshAreaNode(areaNode) {
              districtExplorer.setHoverFeature(null);

              renderAreaPolygons(areaNode);

              //更新选中节点的class
              var $nodeEles = $("#area-tree").find("h2");

              $nodeEles.removeClass("selected");

              var $selectedNode = $nodeEles
                .filter("h2[data-adcode=" + areaNode.getAdcode() + "]")
                .addClass("selected");

              //展开下层节点
              $selectedNode.closest("li").removeClass("hide-sub");

              //折叠下层的子节点
              $selectedNode
                .siblings("ul.sublist")
                .children()
                .addClass("hide-sub");
            }
            //切换区域
            function switch2AreaNode(adcode, callback) {
              // map.setZoom(15);
              if (
                currentAreaNode &&
                "" + currentAreaNode.getAdcode() === "" + adcode
              ) {
                return;
              }

              loadAreaNode(adcode, function (error, areaNode) {
                if (error) {
                  if (callback) {
                    callback(error);
                  }

                  return;
                }

                currentAreaNode = window.currentAreaNode = areaNode;

                //设置当前使用的定位用节点
                districtExplorer.setAreaNodesForLocating([currentAreaNode]);

                refreshAreaNode(areaNode);

                if (callback) {
                  callback(null, areaNode);
                }
              });
              // map.setZoom(15);
            }

            //加载区域
            function loadAreaNode(adcode, callback) {
              districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
                if (error) {
                  if (callback) {
                    callback(error);
                  }

                  console.error(error);

                  return;
                }

                renderAreaPanel(areaNode);

                if (callback) {
                  callback(null, areaNode);
                }
              });
            }
            //全国
            switch2AreaNode(100000);
          }
        );
      });
    },
    addMark(markData) {
      let _this = this;
      _this.markMap.clearMap();
      var viaMarker;
      if (viaMarker) {
        // _this.markMap.remove([viaMarker]);
        // _this.markMap.setMap(null);
      } else {
        if (markData && markData.length > 0) {
          for (var i = 0; i < markData.length; i++) {
            //
            var icon = new AMap.Icon({
              size: new AMap.Size(36, 48), // 图标尺寸
              image: require("@/assets/img/mapPlaceIcon.png"),
              imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(36, 48), // 根据所设置的大小拉伸或压缩图片
            });
            viaMarker = new AMap.Marker({
              position: new AMap.LngLat(
                markData[i].longitude,
                markData[i].latitude
              ),
              icon: icon,
              size: [36, 48],
              // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
              offset: new AMap.Pixel(0, 0),
            });
            _this.markMap.add([viaMarker]);
            var infoWindow = new AMap.InfoWindow({
              offset: new AMap.Pixel(18, -20),
            });
            viaMarker.content =
              "<div class='textBox'><div class='title'>门店信息</div><ul><li><p>门店编号：</p><span>" +
              markData[i].spaceId +
              "</span></li><li><p>门店名称：</p><span>" +
              markData[i].spaceName +
              "</span></li><li><p>门店地址：</p><span>" +
              markData[i].spacePath +
              "</span></li></ul></div>";
            // viaMarker.on("click", markerClick);
            viaMarker.on("mouseover", infoOpen);
            //注释后打开地图时默认关闭信息窗体
            viaMarker.on("mouseout", infoClose);
            infoWindow.close();
            function infoOpen(e) {
              infoWindow.setContent(e.target.content);
              infoWindow.open(_this.markMap, e.target.getPosition());
            }
            function infoClose(e) {
              infoWindow.setContent(null);
              infoWindow.close();
            }
          }
        } else {
          // return
        }
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.mapBox {
  width: 1040px;
  height: 820px;
  margin-top: 50px;
  position: relative;
  #container {
    width: 100%;
    height: 100%;
  }
}
/deep/.top {
  color: #fff !important;
  padding-left: 20px;
  width: 400px;
}
// 撒点弹框
/deep/.amap-info-content {
  padding: 0;
  border: none;
}
/deep/.amap-info-close {
  color: #fff;
}
/deep/.textBox {
  min-width: 400px;
  padding: 0 10px 10px;
  background-image: url(../../../assets/img/windowBck.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  .title {
    text-align: center;
    color: #fff;
    line-height: 40px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    // margin-top: 30px;
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    color: #fff;
    p {
      margin: 0;
    }
    span {
      display: inline-block;
    }
  }
}
.mapText {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 240px;
  height: 170px;
  z-index: 999;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  background-image: url(../../../assets/img/mapText.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06em;
    text-align: center;
  }
  ul,
  li {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
  }
  p {
    margin: 0 !important;
    padding: 0 !important;
  }
  li {
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 10px;
      margin-top: 10px;
    }
    p {
      font-size: 14px;
    }
  }
}
</style>