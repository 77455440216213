import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import echarts from "echarts"
import "./utils/permission"
import "./utils/btnPremi"
import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(Element, { size: "small", zIndex: 3000 })
import "echarts/map/js/china.js"
import $ from "jquery"
import './assets/element-variables.scss'
import 'echarts-liquidfill'
import 'echarts-wordcloud'
//导入字体文件
import '../src/assets/font/dsfont.css'


Vue.prototype.$ = $

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
