// collection.js
// import axios from "axios";
// import Urls from '@/utils/urls'
const state = {
  // getDataUrl: Urls,
  supervisionTable: {
    size: "",
    loading: false,
    columns: [
      {
        col: "序号",
        prop: "series",
        width: "",
      },
      {
        col: "人员名称",
        prop: "name",
        width: "",
      },
      {
        col: "项目名称",
        prop: "project",
        width: "",
      },
      {
        col: "门店名称",
        prop: "storeName",
        width: "",
      },

      {
        col: "预警级别",
        prop: "warnLevel",
        width: "",
      },
      {
        col: "预警类型",
        prop: "warnType",
        width: "",
      },
      {
        col: "预警时长",
        prop: "warnduration",
        width: "",
      },
      {
        col: "开始时间",
        prop: "startTime",
        width: "",
      },
      {
        col: "预警次数",
        prop: "warnNum",
        width: "",
      },
    ],
    data: [
      {
        series: "1",
        name: "师胜",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "6h",
        startTime: "2020-08-10 12:00",
        warnNum: "3",
      },
      {
        series: "2",
        name: "张师胜",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "6h",
        startTime: "2021-10-15 10:00",
        warnNum: "3",
      },
      {
        series: "3",
        name: "张楠",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "2h",
        startTime: "2021-10-17 21:00",
        warnNum: "3",
      },
      {
        series: "4",
        name: "李子涵",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "3h",
        startTime: "2020-10-10 12:00",
        warnNum: "1",
      },
      {
        series: "5",
        name: "王天",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "5h",
        startTime: "2020-11-10 12:00",
        warnNum: "1",
      },

      {
        series: "6",
        name: "张师胜",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "1h",
        startTime: "2021-01-10 12:00",
        warnNum: "3",
      },
      {
        series: "7",
        name: "张楠",
        project: "上海布点项目",
        storeName: "上海南京路门店",
        warnLevel: "一级预警",
        warnType: "离岗次数异常",
        category: "婴儿奶粉",
        warnduration: "6h",
        startTime: "2021-10-10 12:00",
        warnNum: "3",
      },
    ],
    pageArr: {
      // 初始化信息总条数
      dataCount: 0,
      page: 1, //第几页
      // 每页显示多少条
      per_page: 4,
      xia: 0, //下一页或者上一页的第一项索引值
    },
  },
  notes: [
    {
      url: "chat",
      name: "沟通时长",
      num: "3.6h",
    },
    {
      url: "step",
      name: "行动步数",
      num: "1298",
    },
    {
      url: "leaveTimes",
      name: "离岗次数",
      num: "3",
    },
    {
      url: "leaveTime",
      name: "离岗时间",
      num: "30min",
    },
    {
      url: "staticTimes",
      name: "静止次数",
      num: "0",
    },
    {
      url: "warnTime",
      name: "预警时间",
      num: "0.5h",
    },
    {
      url: "staticTime",
      name: "静止时长",
      num: "0",
    },
    {
      url: "warnTimes",
      name: "预警次数",
      num: "1次",
    },
  ],
  cxyRowData:null,
};
const getters = {};
const mutations = {
  setTop10Xl(state, data) {
    state.top10Xl = data;
  },
  setCxyRowData(state,data) {
    state.cxyRowData = data;
  }
};
const actions = {
  async getSpeechAnalysisTable(context, item) {
    // try {
    //   const response = await axios.get(state.getDataUrl.create, {
    //     params: {
    //       sort: item,
    //     }
    //   });
    //   let data = response.data
    //   if (data.code === 200) {
    //     context.commit("setTop10Xl", data.data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  },
};
export default {
  namespaced: true, // 用于在全局引用此文件里的方法时标识这一个的文件名
  state,
  getters,
  mutations,
  actions,
};
