<!-- 话术完成度 -->
<template>
  <div class="dataBox">
    <div class="title">话术完成度</div>
    <div class="mapBox">
      <el-progress
        type="dashboard"
        :percentage="percentage"
        :color="colors"
        :stroke-width="12"
      ></el-progress>
    </div>
  </div>
</template>

<script>
import { completeness } from "@/api/dp/dphead";
export default {
  name: "Vue",
  components: {},
  props: {
    projectId: String,
    date: Array,
    placeCode: String,
  },
  data() {
    return {
      lists: [],
      lastProjectId: "",
      code: "",
      percentage: 0,
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#fc0303", percentage: 100 },
      ],
    };
  },
  watch: {
    placeCode: {
      handler(val) {
        this.code = val;
        this.getData();
      },
    },
    projectId: {
      handler(val) {
        this.lastProjectId = val;
        this.getData();
      },
    },
    lastDate: {
      handler(val) {
        this.getData();
      },
    },
  },
  computed: {
    lastDate() {
      return this.date;
    },
  },
  methods: {
    getData() {
      let option = {
        projectId: this.lastProjectId,
        startDate: this.lastDate[0],
        endDate: this.lastDate[1],
        provinceCode: this.code,
      };
      completeness(option).then((res) => {
        if (res && res.code == 200) {
          let data = res.data.completeness;
          let datas = data.slice(0, -1);
          let numData = Number(datas);
          this.percentage = numData;
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.dataBox {
  width: 420px;
  height: 325px;
  background-image: url(../../../assets/img/rightList.png);
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    padding-left: 30px;
    font-family: "YouSheBiaoTiHei";
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
/deep/.el-progress-circle {
  width: 260px !important;
  height: 260px !important;
}
.mapBox {
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
}
/deep/.el-progress__text {
  font-size: 40px !important;
  color: #ffffff;
  // font-weight: bolder !important;
}
/deep/ .el-progress path:first-child {
  // 修改进度条背景色
  stroke: #7050e2;
}
</style>