<!-- top排名 -->
<template>
  <div class="topBox">
    <div class="title">top排名</div>
    <div class="tabBox">
      <div
        class="men"
        :class="IsCheck == 1 ? 'active-person' : ''"
        @click="typeChange(1)"
      >
        门店TOP20
      </div>
      <div
        class="ren"
        :class="IsCheck == 1 ? '' : 'active-person'"
        @click="typeChange(2)"
      >
        人员TOP20
      </div>
    </div>
    <!-- 列表 -->
    <div class="userLists">
      <ul v-if="IsCheck == 1">
        <li
          v-for="(item, index) in spaceLists"
          :key="index"
          @click="placeChange(item, index)"
          :class="personActiveIndex == index ? 'active-li' : ''"
        >
          <div class="left">
            <div class="perIcon">
              <img
                src="../../../assets/img/userInit.png"
                alt=""
                v-if="personActiveIndex != index"
              />
              <img
                src="../../../assets/img/userCheck.png"
                alt=""
                v-if="personActiveIndex == index"
              />
            </div>
            <div class="nameBox">
              <div class="name">{{ item.spaceName }}</div>
              <div class="place">{{ item.spacePath }}</div>
            </div>
          </div>
          <div class="right">
            <div class="wancheng">
              <p>访问数</p>
              <span>{{ item.patrolSpace }}</span>
            </div>
          </div>
        </li>
      </ul>
      <!-- 人员 -->
      <ul v-if="IsCheck == 2">
        <li
          v-for="(item, index) in dataLists"
          :key="index"
          @click="userChange(item, index)"
          :class="dianActiveIndex == index ? 'active-li' : ''"
        >
          <div class="left">
            <div class="perIcon">
              <img
                src="../../../assets/img/dianInit.png"
                alt=""
                v-if="dianActiveIndex != index"
              />
              <img
                src="../../../assets/img/dianCheck.png"
                alt=""
                v-if="dianActiveIndex == index"
              />
            </div>
            <div class="nameBox">
              <div class="name">{{ item.nickName }}2222222222</div>
              <div class="place">{{ item.roleName }}</div>
            </div>
          </div>
          <div class="right">
            <div class="xundian">
              <p>巡店数</p>
              <span>{{ item.patrolSpace }}</span>
            </div>
            <div class="wancheng">
              <p>完成度</p>
              <span>{{ item.completeness }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { userTop, spaceTop } from "@/api/dp/dphead";
export default {
  name: "Vue",
  components: {},
  props: {
    projectId: String,
    date: Array,
    placeCode: String,
  },
  data() {
    return {
      IsCheck: 1,
      personActiveIndex: null,
      dianActiveIndex: null,
      lastProjectId: "",
      dataLists: [],
      spaceLists: [],
      userId: "",
      spaceId: "",
      code: "",
    };
  },
  watch: {
    placeCode: {
      handler(val) {
        this.code = val;
        this.initList();
      },
    },
    projectId: {
      handler(val) {
        this.lastProjectId = val;
        this.initList();
      },
    },
    lastDate: {
      handler(val) {
        this.initList();
      },
    },
  },
  computed: {
    lastDate() {
      return this.date;
    },
  },
  methods: {
    typeChange(e) {
      this.IsCheck = e;
      this.initList();
    },
    initList() {
      this.personActiveIndex = null;
      this.dianActiveIndex = null;
      let option = {
        projectId: this.lastProjectId,
        startDate: this.lastDate[0],
        endDate: this.lastDate[1],
        provinceCode: this.code,
      };
      if (this.IsCheck == 1) {
        spaceTop(option).then((res) => {
          if (res && res.code == 200) {
            this.spaceLists = res.data;
          }
        });
      } else {
        userTop(option).then((res) => {
          if (res && res.code == 200) {
            this.dataLists = res.data;
          }
        });
      }
    },
    userChange(item, index) {
      this.spaceId = "";
      this.userId = item.userId;
      let obj = {
        spaceId: this.spaceId,
        userId: this.userId,
      };
      this.$emit("userChange", this.userId);
      this.dianActiveIndex = index;
    },
    placeChange(item, index) {
      this.userId = "";
      this.spaceId = item.spaceId;
      this.$emit("placeChange", this.spaceId);
      this.personActiveIndex = index;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.topBox {
  margin: 0px 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/img/leftBck.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
  .title {
    font-family: "YouSheBiaoTiHei";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    margin-left: 24px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: 0.02em;
    font-weight: 800;
  }
  .tabBox {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
    font-weight: 800;
    padding: 0 10px;
    margin-bottom: 20px;
    div {
      width: 194px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      background-image: url(../../../assets/img/initperson.png);
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      color: #ffffff;
      cursor: pointer;
    }
    .active-person {
      background-image: url(../../../assets/img/check.png);
    }
  }
}
// 列表
::-webkit-scrollbar {
  display: none;
}
.userLists {
  max-height: 830px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ul {
    margin: 0;
    padding: 0 10px;
    li {
      display: flex;
      justify-content: space-between;
      height: 74px;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
      background-image: url(../../../assets/img/personList.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      color: #fdfdfd;
      padding: 10px;
      box-sizing: border-box;
      .left {
        display: flex;
        justify-content: flex-start;

        .perIcon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-left: 6px;
          margin-top: 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .nameBox {
          margin-left: 10px;
          max-width: 200px;

          .name {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .place {
            font-size: 14px;
            line-height: 27px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .right {
        .xundian,
        .wancheng {
          display: flex;
          justify-content: flex-start;
          line-height: 24px;
          padding: 0 10px;
          box-sizing: border-box;
          border-radius: 8px;
          height: 24px;
          p {
            font-size: 12px;
            margin-right: 7px;
            color: #ffffff;
          }
          span {
            display: inline-block;
            // font-weight: 700;
            font-size: 18px;
          }
        }
        .xundian {
          border: 1px solid #6966fb;
          margin-bottom: 8px;
          // width: 90px;
        }
        .wancheng {
          border: 1px solid #ffb14d;
          span {
            color: #ffb14d;
            font-size: 12px;
          }
        }
      }
    }
    .active-li {
      background-image: url(../../../assets/img/personCheck.png);
    }
  }
}
</style>