// collection.js
// import axios from "axios";
// import Urls from '@/utils/urls'
const state = {
  // getDataUrl: Urls,
  // 王牌话术分析列表
  speechAnalysisTable: {
    size: "",
    loading: false,
    columns: [
      {
        col: "序列号",
        prop: "series",
        width: ""
      },
      {
        col: "王牌销售姓名",
        prop: "name",
        width: ""
      },
      {
        col: "项目",
        prop: "project",
        width: ""
      },
      {
        col: "行业",
        prop: "profession",
        width: ""
      },

      {
        col: "区域",
        prop: "area",
        width: ""
      },
      {
        col: "品牌",
        prop: "brand",
        width: ""
      },
      {
        col: "品类",
        prop: "category",
        width: ""
      },
      {
        col: "商品",
        prop: "wares",
        width: ""
      },
      {
        col: "标准话术匹配度",
        prop: "matchRate",
        width: ""
      },
      {
        col: "新增话术条数",
        prop: "addCount",
        width: ""
      },
      {
        col: "所选入话术库",
        prop: "choosen",
        width: ""
      },
      {
        col: "更新时间",
        prop: "createTime",
        width: ""
      }
    ],
    data: [
      // {
      //   series: "1",
      //   name: "师胜",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华东",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "38%",
      //   addCount: "490",
      //   choosen: "3",
      //   createTime: "2020-10-10 12:00"
      // },
      // {
      //   series: "2",
      //   name: "张师胜",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华东",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "38%",
      //   addCount: "90",
      //   choosen: "13",
      //   createTime: "2020-10-10 23:00"
      // },
      // {
      //   series: "3",
      //   name: "张楠",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华北",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "38%",
      //   addCount: "40",
      //   choosen: "2",
      //   createTime: "2020-09-10 12:00"
      // },
      // {
      //   series: "4",
      //   name: "李子涵",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华东",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "18%",
      //   addCount: "90",
      //   choosen: "13",
      //   createTime: "2020-10-10 23:00"
      // },
      // {
      //   series: "5",
      //   name: "王天",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华南",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "38%",
      //   addCount: "40",
      //   choosen: "2",
      //   createTime: "2020-09-10 12:00"
      // },

      // {
      //   series: "6",
      //   name: "张师胜",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华东",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "38%",
      //   addCount: "90",
      //   choosen: "13",
      //   createTime: "2020-10-10 23:00"
      // },
      // {
      //   series: "7",
      //   name: "张楠",
      //   project: "上海布点项目",
      //   profession: "母婴",
      //   area: "华北",
      //   brand: "a1",
      //   category: "婴儿奶粉",
      //   wares: "a2初5段500克",
      //   matchRate: "38%",
      //   addCount: "40",
      //   choosen: "2",
      //   createTime: "2020-09-10 12:00"
      // }
    ],
    pageArr: {
      // 初始化信息总条数
      dataCount: 0,
      page: 1, //第几页
      // 每页显示多少条
      per_page: 4,
      xia: 0 //下一页或者上一页的第一项索引值
    }
  },
  // 标准话术库
  wordsLibaryTable: {
    size: "",
    loading: false,
    columns: [
      {
        col: "话术ID",
        prop: "series",
        width: ""
      },
      {
        col: "话术内容",
        prop: "name",
        width: "362"
      },
      {
        col: "项目",
        prop: "project",
        width: ""
      },
      {
        col: "行业",
        prop: "profession",
        width: ""
      },
      {
        col: "品类",
        prop: "category",
        width: ""
      },
      {
        col: "品牌",
        prop: "brand",
        width: ""
      },
      {
        col: "区域",
        prop: "area",
        width: ""
      },

      {
        col: "话术类型",
        prop: "wares",
        width: ""
      },
      {
        col: "话术来源",
        prop: "matchRate",
        width: ""
      },
      {
        col: "商品",
        prop: "addCount",
        width: ""
      },
      {
        col: "创建时间",
        prop: "createTime",
        width: ""
      }
    ],
    data: [
      {
        series: "1234",
        name: "这是一段描述，关于这个话术的描述关于这个话术的描述",
        project: "上海布点项目",
        profession: "母婴",
        area: "华东",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "38%",
        addCount: "490",
        choosen: "3",
        createTime: "2020-10-10 12:00"
      },
      {
        series: "2123",
        name: "张师胜",
        project: "上海布点项目",
        profession: "母婴",
        area: "华东",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "38%",
        addCount: "90",
        choosen: "13",
        createTime: "2020-10-10 23:00"
      },
      {
        series: "1234",
        name: "张楠",
        project: "上海布点项目",
        profession: "母婴",
        area: "华北",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "38%",
        addCount: "40",
        choosen: "2",
        createTime: "2020-09-10 12:00"
      },
      {
        series: "412313",
        name: "李子涵",
        project: "上海布点项目",
        profession: "母婴",
        area: "华东",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "18%",
        addCount: "90",
        choosen: "13",
        createTime: "2020-10-10 23:00"
      },
      {
        series: "5123",
        name: "王天",
        project: "上海布点项目",
        profession: "母婴",
        area: "华南",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "38%",
        addCount: "40",
        choosen: "2",
        createTime: "2020-09-10 12:00"
      },

      {
        series: "63123",
        name: "张师胜",
        project: "上海布点项目",
        profession: "母婴",
        area: "华东",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "38%",
        addCount: "90",
        choosen: "13",
        createTime: "2020-10-10 23:00"
      },
      {
        series: "74124",
        name: "张楠",
        project: "上海布点项目",
        profession: "母婴",
        area: "华北",
        brand: "a1",
        category: "婴儿奶粉",
        wares: "a2初5段500克",
        matchRate: "38%",
        addCount: "40",
        choosen: "2",
        createTime: "2020-09-10 12:00"
      }
    ],
    pageArr: {
      // 初始化信息总条数
      dataCount: 0,
      page: 1, //第几页
      // 每页显示多少条
      per_page: 4,
      xia: 0 //下一页或者上一页的第一项索引值
    }
  },
  // 音频记录
  audioListTable: {
    size: "",
    loading: false,
    columns: [
      {
        col: "人员编号",
        prop: "series",
        width: ""
      },
      {
        col: "人员名称",
        prop: "name",
        width: ""
      },
      {
        col: "项目编号",
        prop: "projectSeries",
        width: ""
      },
      {
        col: "项目名称",
        prop: "project",
        width: ""
      },
      {
        col: "门店编号",
        prop: "storeSeries",
        width: ""
      },

      {
        col: "门店名称",
        prop: "storeName",
        width: ""
      },
      {
        col: "设备号",
        prop: "deviceNumber",
        width: ""
      },
      {
        col: "音频时长",
        prop: "audioDuration",
        width: ""
      },
      {
        col: "生成时间",
        prop: "createTime",
        width: ""
      }
    ],
    data: [
      {
        series: "1",
        name: "师胜",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "9908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      },
      {
        series: "2",
        name: "张星",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "91908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      },
      {
        series: "3",
        name: "王伊",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "9908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      },
      {
        series: "4",
        name: "何最",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "9908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      },
      {
        series: "5",
        name: "王珊珊",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "9908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      },

      {
        series: "6",
        name: "李岚",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "9908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      },
      {
        series: "7",
        name: "冯涵",
        projectSeries: "1210",
        project: "上海布点项目",
        storeSeries: "9908",
        storeName: "盒马上海生鲜店",
        deviceNumber: "90",
        audioDuration: "1h",
        createTime: "2020-10-10 12:00"
      }
    ],
    pageArr: {
      // 初始化信息总条数
      dataCount: 0,
      page: 1, //第几页
      // 每页显示多少条
      per_page: 4,
      xia: 0 //下一页或者上一页的第一项索引值
    }
  },
  //账户管理
  jurisdiction: {
    size: "",
    loading: false,
    columns: [
      {
        col: "客户",
        prop: "name",
        width: ""
      },
      {
        col: "品牌",
        prop: "brand",
        width: ""
      },
      {
        col: "客户状态",
        prop: "brandstate",
        width: ""
      },
      {
        col: "手机号",
        prop: "phone",
        width: ""
      },
      {
        col: "最后登录时间",
        prop: "deadline",
        width: ""
      },
      {
        col: "数据权限",
        prop: "permission",
        width: ""
      }
    ],

    data: [
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      },
      {
        name: "张楠",
        brand: "德福",
        brandstate: "启用",
        phone: "152997144",
        deadline: "2020-11-1",
        permission: "admin"
      }
    ]
  },
  //角色管理
  character: {
    size: "",
    loading: false,
    columns: [
      {
        col: "角色名称",
        prop: "name",
        width: ""
      },
      {
        col: "描述",
        prop: "brand",
        width: ""
      },
      {
        col: "创建时间",
        prop: "time",
        width: ""
      }
    ],
    data: [
      {
        name: "系统管理员",
        brand: "这里是描述，这里是描述",
        time: "2020-12-10"
      },
      {
        name: "系统管理员",
        brand: "这里是描述，这里是描述",
        time: "2020-12-10"
      },
      {
        name: "系统管理员",
        brand: "这里是描述，这里是描述",
        time: "2020-12-10"
      },
      {
        name: "系统管理员",
        brand: "这里是描述，这里是描述",
        time: "2020-12-10"
      },
      {
        name: "系统管理员",
        brand: "这里是描述，这里是描述",
        time: "2020-12-10"
      },
      {
        name: "系统管理员",
        brand: "这里是描述，这里是描述",
        time: "2020-12-10"
      }
    ]
  },
  //客户管理
  customTableData: {
    size: "",
    loading: false,
    columns: [
      {
        col: "客户",
        prop: "name",
        width: ""
      },
      {
        col: "客户类型",
        prop: "type",
        width: ""
      },
      {
        col: "品牌",
        prop: "brand",
        width: ""
      },
      {
        col: "客户状态",
        prop: "brandstate",
        width: ""
      },
      {
        col: "备注",
        prop: "note",
        width: ""
      },
      {
        col: "最后修改时间",
        prop: "deadline",
        width: ""
      }
    ],
    data: [
      {
        name: "雀巢",
        type: "直客",
        brand: "雀巢",
        brandstate: "启用",
        note: "————",
        deadline: "2020-11-1"
      }
    ]
  }
}
const getters = {}
const mutations = {
  setTop10Xl(state, data) {
    state.top10Xl = data
  }
}
const actions = {
  async getSpeechAnalysisTable(context, item) {
    // try {
    //   const response = await axios.get(state.getDataUrl.create, {
    //     params: {
    //       sort: item,
    //     }
    //   });
    //   let data = response.data
    //   console.log(data);
    //   if (data.code === 200) {
    //     context.commit("setTop10Xl", data.data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  }
}
export default {
  namespaced: true, // 用于在全局引用此文件里的方法时标识这一个的文件名
  state,
  getters,
  mutations,
  actions
}
