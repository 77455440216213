import { render, staticRenderFns } from "./hotWords.vue?vue&type=template&id=9944ec82&scoped=true&"
import script from "./hotWords.vue?vue&type=script&lang=js&"
export * from "./hotWords.vue?vue&type=script&lang=js&"
import style0 from "./hotWords.vue?vue&type=style&index=0&id=9944ec82&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9944ec82",
  null
  
)

export default component.exports